@layer components {
  .radio-button {
    @apply border border-grey-darker border-solid;
    @apply rounded flex h-10 items-center justify-start;

    position: relative;
    overflow: clip;
    padding: 7px;
    transition: max-inline-size 0.3s ease-out, border-color 0.2s ease-out,
      box-shadow 0.2s ease-out;

    & svg {
      @apply text-grey;

      transition: color 0.15s ease-out;
    }

    &:hover {
      @apply border-primary shadow-md;

      & svg {
        @apply text-black;
      }
    }

    & > span {
      @apply text-xxs ml-2 inline-flex items-center;

      &:only-child {
        margin-inline-start: 0;
      }
    }

    & input {
      position: absolute;
      block-size: 100%;
      inline-size: 100%;
      inset-block-start: 0;
      inset-inline-start: 0;
    }

    & .focus-style {
      position: absolute;
      margin: 0;
      inset: 0;
      pointer-events: none;
    }
  }

  .radio-button--collapsed {
    max-inline-size: 40px;
  }

  .radio-button--expanded {
    max-inline-size: 150px;
  }

  .radio-button--selected {
    @apply border-primary;

    max-inline-size: 150px;
    & svg {
      @apply text-black;
    }
  }

  .appear-enter-active,
  .appear-leave-active {
    transition: all 0.2s 0.2s ease-out;
  }

  .appear-enter,
  .appear-leave-to {
    opacity: 0;
  }

  .appear-leave-active,
  .appear-leave-to {
    transition: all 0.2s 0.2s ease-in;
  }
}
