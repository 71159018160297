@layer components {
  .breadcrumbs {
    display: flex;
    padding: 0;
    margin: 0;

    list-style: none;

    & li {
      display: flex;
      align-items: center;

      &:not(:first-child)::before {
        display: inline-block;
        block-size: 12px;
        content: '';
        inline-size: 12px;
        margin-inline: 0.25rem;
        @if $site == 'lassa' {
          background-image: url("data:image/svg+xml,%3Csvg id='icon-arrow-right' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cpath fill='%23e10e21' d='M23.962 12.69a.496.496 0 0 0-.11-.545l-7.998-7.999a.5.5 0 0 0-.707.707L22.293 12H.5a.5.5 0 0 0 0 1h21.793l-7.146 7.146a.5.5 0 0 0 .708.707l7.999-7.999a.533.533 0 0 0 .108-.163z'%3E%3C/path%3E%3C/svg%3E%0A");
        }
        @if $site == 'sailun' {
          background-image: url("data:image/svg+xml,%3Csvg id='icon-arrow-right' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cpath fill='%23f39800' d='M23.962 12.69a.496.496 0 0 0-.11-.545l-7.998-7.999a.5.5 0 0 0-.707.707L22.293 12H.5a.5.5 0 0 0 0 1h21.793l-7.146 7.146a.5.5 0 0 0 .708.707l7.999-7.999a.533.533 0 0 0 .108-.163z'%3E%3C/path%3E%3C/svg%3E%0A");
        }
        @if $site == 'maxam' {
          background-image: url("data:image/svg+xml,%3Csvg id='icon-arrow-right' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cpath fill='%230077c0' d='M23.962 12.69a.496.496 0 0 0-.11-.545l-7.998-7.999a.5.5 0 0 0-.707.707L22.293 12H.5a.5.5 0 0 0 0 1h21.793l-7.146 7.146a.5.5 0 0 0 .708.707l7.999-7.999a.533.533 0 0 0 .108-.163z'%3E%3C/path%3E%3C/svg%3E%0A");
        }
        @if $site == 'goldGarantie' {
          background-image: url("data:image/svg+xml,%3Csvg id='icon-arrow-right' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cpath fill='%23e10e21' d='M23.962 12.69a.496.496 0 0 0-.11-.545l-7.998-7.999a.5.5 0 0 0-.707.707L22.293 12H.5a.5.5 0 0 0 0 1h21.793l-7.146 7.146a.5.5 0 0 0 .708.707l7.999-7.999a.533.533 0 0 0 .108-.163z'%3E%3C/path%3E%3C/svg%3E%0A");
        }
      }
    }

    & a {
      display: inline-block;
      color: currentColor;
      text-decoration: none;
    }
  }
}
