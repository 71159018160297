/* purgecss ignore */
div.ppms_cm_consent_bar.ppms_cm_consent_bar,
div.ppms_cm_popup_overlay.ppms_cm_popup_overlay {
  transform: translate3d(0, 100%, 0) !important;
  transition: transform 350ms easeOut !important;

  &.show {
    transform: translate3d(0, 0, 0) !important;
  }
}

span.ppms_cm_footer__powered_by {
  color: #737373 !important;
}
