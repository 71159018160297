.button {
  @apply skew no-underline shadow-md;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-block-size: 40px;
  padding-block: 7px;
  padding-inline: 20px;
  text-align: center;
  transition: box-shadow 0.2s ease-in-out;

  & > * {
    @apply deskew;

    pointer-events: none;
  }

  @if $site == 'lassa' {
    border-radius: 6px 10px;
  }

  @if $site == 'sailun' {
    @apply rounded-full;
  }

  @if $site == 'maxam' {
    @apply rounded-full shadow-none;
  }

  @if $site == 'goldGarantie' {
    letter-spacing: 0.063em;
    text-transform: uppercase;
  }

  &:focus,
  &:hover {
    @apply shadow-lg;
  }
}

@variants current {
  .button-primary {
    @apply bg-primary text-sm font-bold text-white;

    transition: all 0.15s ease-out;

    &:hover {
      @if $site == 'lassa' {
        @apply bg-primary-dark;
      }

      @if $site == 'sailun' {
        @apply bg-primary text-white;
      }

      @if $site == 'maxam' {
        @apply bg-primary text-white;
      }

      @if $site == 'goldGarantie' {
        @apply bg-primary-dark;
      }
    }
  }
}

.button-secondary {
  @apply text-primary bg-white text-sm font-extrabold;

  @if $site == 'maxam' {
    border: 2px solid #0077c088;
    background-color: transparent;
    font-weight: 700;
  }
}

@responsive {
  .button-default {
    @apply px-5 text-sm leading-tight;

    max-block-size: 40px;

    @if $site == 'lassa' {
      @apply py-3;
    }

    @if $site == 'sailun' {
      @apply py-10px;
    }

    @if $site == 'maxam' {
      @apply py-10px;
    }

    @if $site == 'goldGarantie' {
      @apply py-3;
    }
  }

  .button-sm {
    @apply leading-20/12 px-4 text-xs;

    min-block-size: 30px;
    padding-block: 5px;
  }
}

.button-group {
  @apply whitespace-no-wrap border-collapse;

  & .button-group__button {
    @apply text-xxs inline-flex h-10 w-10 items-center justify-center;
    @apply border relative bg-white font-bold text-black no-underline;
    box-sizing: border-box;

    inline-size: 40px;
    min-block-size: 40px;

    &.is-active {
      @apply border-primary z-10;
    }

    &:first-child {
      @apply rounded-tl rounded-bl;
    }

    &:last-child {
      @apply rounded-tr rounded-br;
    }

    & + .button-group__button {
      margin-inline-start: -1px;
    }
  }
}

.button-pagination {
  padding: 13px;
  background: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  inline-size: 55px;
  min-block-size: 50px;

  &:focus,
  &:hover {
    @apply shadow-lg;
  }

  @if $site == 'lassa' {
    border-radius: 6px 10px;

    @apply skew;
  }

  & > * {
    @if $site == 'lassa' {
      @apply deskew;
    }
  }

  @if $site == 'sailun' {
    @apply rounded-full;

    inline-size: 50px;
  }
}

.button-label {
  @apply button-secondary leading-none;

  letter-spacing: 1px;
  min-block-size: 30px;

  @if $site == 'lassa' {
    @apply px-10px py-2;

    border-radius: 5px 8px;
  }

  @if $site == 'sailun' {
    @apply rounded p-2;
  }

  @if $site == 'maxam' {
    @apply rounded-none border-none p-2;

    background-color: theme('colors.white');
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  }

  @if $site == 'goldGarantie' {
    @apply px-10px py-2;
  }

  &:hover {
    @apply shadow-md;
  }
}

.button-icon {
  @apply button-secondary leading-none;

  letter-spacing: 1px;
  min-block-size: 30px;
  padding-block: 3px;

  @if $site == 'sailun' {
    @apply rounded px-2;
  }
  @if $site == 'maxam' {
    background-color: theme('colors.white');
  }
}
