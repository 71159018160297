@layer components {
  select {
    border: 1px solid #ededed;
    border-radius: 4px;
    appearance: none;
    background-color: #fafafa;
    font-size: 1rem;
    inline-size: auto !important;
    line-height: 1.75;
    padding-inline: 0.5rem 2.25rem;
  }

  .select:where(:not(.readonly)) {
    position: relative;
    display: flex;
    block-size: 3rem;
    inline-size: 100%;
    transition: border 0.2s ease-out;

    & select {
      &::-ms-expand {
        display: none;
      }
    }

    &:focus-within {
      border-color: theme('colors.primary');
    }

    &::after {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1d1d1b;
      content: '▾';
      font-size: 1.2em;
      inline-size: 2.25rem;
      inset-block: 0;
      inset-inline-end: 0;
      opacity: 0.8;
      padding-block-end: 5px;
      pointer-events: none;
    }
  }
}
