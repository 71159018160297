.video {
  position: relative;
  block-size: 0;
  padding-block-end: 56.25%; /* 16:9 */

  & iframe {
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
  }
}
