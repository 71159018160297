/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 1031;
  background: theme('colors.primary');
  block-size: 2px;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
}

/* Fancy blur effect */
#nprogress .peg {
  position: absolute;
  display: block;
  block-size: 100%;
  box-shadow: 0 0 10px theme('colors.primary'), 0 0 5px theme('colors.primary');
  inline-size: 100px;
  inset-inline-end: 0;
  opacity: 1;
  transform: rotate(3deg) translate(0, -4px);
}
