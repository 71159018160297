.not-found {
  @apply bg-cover bg-center rounded w-full text-white p-5;

  & h2,
  & h3 {
    text-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
  }

  & h2 {
    @apply text-4xl;

    line-height: 1.222222222;
  }

  & h3 {
    @apply text-xl;

    line-height: 1.35;
  }

  & p {
    @apply m-0 text-sm font-semibold leading-normal;
  }

  & a {
    @apply no-underline text-white border-b border-primary;
  }
}
