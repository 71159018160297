@responsive {
  .scooch-b-magic {
    inset-block-end: calc(100% - 15px);
  }

  .scooch-center {
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }

  .scooch-t-half {
    transform: translateY(50%);
  }

  .-scooch-l-half {
    transform: translateX(-50%);
  }

  .-scooch-t-half {
    transform: translateY(-50%);
  }

  .-scooch-t-5px {
    inset-block-start: -5px;
  }

  .-scooch-l-5px {
    inset-inline-start: -5px;
  }

  .scooch-t-1 {
    inset-block-start: 1rem;
  }

  .-scooch-t-2 {
    inset-block-start: -0.5rem;
  }

  .-scooch-t-8 {
    inset-block-start: -2rem;
  }

  .-scooch-t-12 {
    inset-block-start: -3rem;
  }

  .scooch-l-1 {
    inset-inline-start: 1rem;
  }

  .scooch-r-1 {
    inset-inline-end: 1rem;
  }

  .scooch-l-2 {
    inset-inline-start: 2rem;
  }

  .-scooch-l-8 {
    inset-inline-start: -2rem;
  }

  .scooch-left {
    inset-inline-start: -0.125rem;
    padding-inline-start: 0.125rem;
  }
}
