.logo {
  block-size: 20px;

  @screen md {
    block-size: 30px;
    margin-block: 27px 23px;

    @if $site == 'maxam' {
      margin-block: 23px;
    }
  }

  @screen lg {
    @if $site == 'lassa' {
      block-size: 34px;
      margin-block: 27px 19px;
    }

    @if $site == 'sailun' {
      block-size: 40px;
      margin-block: 25px 15px;
    }

    @if $site == 'maxam' {
      block-size: 40px;
      margin-block: 20px;
    }
  }
}
