.tyre-group-hero {
  @apply card overflow-visible mt-10 mb-25;

  @if $site == 'lassa' {
    @apply bg-grey-lightest;
  }

  @if $site == 'sailun' {
    @apply bg-white;
  }

  &::after {
    @if $site == 'sailun' {
      @apply absolute block right-0 bottom-0;

      width: 100%;
      height: 50%;
      background-image: url('/assets/images/sailun-bg.svg');
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      pointer-events: none;
      transform: rotate(180deg);
    }

    @screen lg {
      @if $site == 'sailun' {
        background-image: url('/assets/images/sailun-reifen-hintergrund-verlauf-hero.svg');
        background-position: 100% 50%;
        transform: none;
      }
    }
  }
}
