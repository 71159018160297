.service-teaser__icon {
  @apply w-30px h-30px flex-shrink-0 mr-5px relative -scooch-t-5px
    -scooch-l-5px text-grey-darker;

  transition: color 0.2s ease-out;

  @if $site == 'sailun' {
    @apply text-primary;
  }
}

.service-teaser {
  @apply block relative h-full shadow-none text-grey fill-current no-underline
    outline-none rounded;

  transition: all 0.2s ease-out !important;

  @if $site == 'lassa' {
    @apply border border-primary;
  }

  @if $site == 'goldGarantie' {
    @apply border border-primary;
  }

  @if $site == 'xxlGarantie' {
    @apply border border-primary;
  }

  &:focus,
  &:hover {
    @apply shadow-lg bg-white border-transparent;

    & .service-teaser__icon {
      @if $site == 'lassa' {
        @apply text-primary;
      }
      @if $site == 'goldGarantie' {
        @apply text-primary;
      }

      @if $site == 'sailun' {
        @apply text-blue;
      }
    }
  }
}
