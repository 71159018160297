.bg-line {
  @apply relative;

  min-block-size: 1rem;

  &::after {
    @if $site == 'lassa' {
      @apply border-b inset-x-0 top-0 block;

      position: absolute;
      z-index: -1;
      block-size: 50%;
      content: '';
    }

    @if $site == 'goldGarantie' {
      @apply border-b inset-x-0 top-0 block;

      position: absolute;
      z-index: -1;
      block-size: 50%;
      content: '';
    }

    @if $site == 'xxlGarantie' {
      @apply border-b inset-x-0 top-0 block;

      position: absolute;
      z-index: -1;
      block-size: 50%;
      content: '';
    }
  }

  & > * {
    @if $site == 'lassa' {
      @apply bg-white;
    }

    @if $site == 'goldGarantie' {
      @apply bg-white;
    }

    @if $site == 'xxlGarantie' {
      @apply bg-white;
    }
  }
}

.bg-secondary {
  @if $site == 'lassa' {
    background-color: theme('colors.white');
  }

  @if $site == 'sailun' {
    background-color: theme('colors.transparent');
  }

  @if $site == 'goldGarantie' {
    background-color: theme('colors.white');
  }

  @if $site == 'xxlGarantie' {
    background-color: theme('colors.white');
  }
}

.bg-full {
  @apply relative;

  &::before {
    @apply absolute inset-y-0 block w-screen;

    z-index: -1;
    background-color: inherit;
    content: '';
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }

  &.bg-border-t-2 {
    &::before {
      @apply border-t;
    }
  }
}

.bg-multiply {
  background-blend-mode: multiply;
}
