body {
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;

  @if $site == 'sailun' {
    background-image: url('/assets/images/sailun-bg.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
