@if $site == 'sailun' {
  .teaser__text {
    & .uppercase {
      @apply hidden;

      @screen lg {
        @apply block;
      }
    }
  }
}

.teaser__badge {
  @apply max-w-150px w-auto ml-auto h-full;

  min-height: 30px;
  max-height: 9vw;

  @media (min-width: 576px) {
    max-height: 5vw;
  }

  @media (min-width: 660px) {
    max-height: 6vw;
  }

  @screen md {
    max-height: 1.875rem;
  }

  @screen lg {
    max-height: 5rem;
  }
}

.teaser-carousel {
  max-height: 63.75vw;

  @screen md {
    max-height: 16.3125rem;
  }
}
