.animate-color {
  transition: color 0.15s ease-in-out;

  &:hover,
  &:focus {
    @apply text-dynamic;
  }
}

.animate-all {
  transition: all 0.15s ease-in-out !important;
}
