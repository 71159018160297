.text-dynamic {
  @if $site == 'lassa' {
    color: theme('colors.primary');
  }

  @if $site == 'sailun' {
    color: theme('colors.blue');
  }

  @if $site == 'goldGarantie' {
    color: theme('colors.primary');
  }

  @if $site == 'xxlGarantie' {
    color: theme('colors.primary');
  }
}

.text-indent-0 {
  text-indent: 0;
}

.text-balance {
  text-wrap: balance;
}
