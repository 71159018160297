<template>
  <fieldset
    class="form-input"
    :class="{ readonly: readonly }"
    v-if="numOfTyres"
  >
    <legend>{{ input.legend }}</legend>

    <!-- 4 tyres -->
    <svg
      v-if="numOfTyres === 4"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="548"
      height="344"
      viewBox="0 0 548 344"
    >
      <defs>
        <rect id="b" width="43" height="51" x="-1.5" y="-1.5" rx="5" />
        <rect id="c" width="43" height="51" x="106.5" y="-1.5" rx="5" />
        <rect id="d" width="43" height="51" x="-1.5" y="126.5" rx="5" />
        <rect id="e" width="43" height="51" x="106.5" y="126.5" rx="5" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(1)"
          @keydown="e => handleKey(e, 1)"
          aria-label="Reifen 1"
          :aria-checked="readonly ? null : checkedItems.includes(1).toString()"
          :class="{ checked: readonly && checkedItems.includes(1) }"
        >
          <use xlink:href="#b" />
          <rect
            width="39"
            height="47"
            x=".5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="27">1</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(2)"
          @keydown="e => handleKey(e, 2)"
          aria-label="Reifen 2"
          :aria-checked="readonly ? null : checkedItems.includes(2).toString()"
          :class="{ checked: readonly && checkedItems.includes(2) }"
        >
          <use xlink:href="#c" />
          <rect
            width="39"
            height="47"
            x="108.5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="123.5" y="27">2</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(3)"
          @keydown="e => handleKey(e, 3)"
          aria-label="Reifen 3"
          :aria-checked="readonly ? null : checkedItems.includes(3).toString()"
          :class="{ checked: readonly && checkedItems.includes(3) }"
        >
          <use xlink:href="#d" />
          <rect
            width="39"
            height="47"
            x=".5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="155">3</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(4)"
          @keydown="e => handleKey(e, 4)"
          aria-label="Reifen 4"
          :aria-checked="readonly ? null : checkedItems.includes(4).toString()"
          :class="{ checked: readonly && checkedItems.includes(4) }"
        >
          <use xlink:href="#e" />
          <rect
            width="39"
            height="47"
            x="108.5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="123.5" y="155">4</tspan>
          </text>
        </g>
        <path
          fill="#0077C0"
          fill-rule="nonzero"
          d="M240 88h68v1h-68zm34.5 1v63h-1V89zm0 64v63h-1v-63zM240 216h68v1h-68z"
          opacity=".2"
        />
        <path d="M264 84V64h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 80V68m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <path d="M264 240v-20h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 236v-12m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <text
          fill="#191919"
          font-family="OpenSans-Regular, Open Sans"
          font-size="16"
        >
          <tspan x="50.4" y="281">
            Traktor, Teleskoplader, Radlader, Selbstfahrende Feldspritze
          </tspan>
        </text>
        <text
          fill="#1d1d1b"
          font-family="OpenSans-Regular, Open Sans"
          font-size="12"
        >
          <tspan x="274" y="313" text-anchor="middle">
            Bitte betroffene Reifen und Position wählen
          </tspan>
        </text>
      </g>
    </svg>
    <!-- 6 tyres -->
    <svg
      v-if="numOfTyres === 6"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="548"
      height="344"
      viewBox="0 0 548 344"
    >
      <defs>
        <rect id="b" width="43" height="51" x="-1.5" y="-1.5" rx="5" />
        <rect id="c" width="43" height="51" x="106.5" y="-1.5" rx="5" />
        <rect id="d" width="43" height="51" x="-1.5" y="62.5" rx="5" />
        <rect id="e" width="43" height="51" x="106.5" y="62.5" rx="5" />
        <rect id="f" width="43" height="51" x="-1.5" y="126.5" rx="5" />
        <rect id="g" width="43" height="51" x="106.5" y="126.5" rx="5" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(1)"
          @keydown="e => handleKey(e, 1)"
          aria-label="Reifen 1"
          :aria-checked="readonly ? null : checkedItems.includes(1).toString()"
          :class="{ checked: readonly && checkedItems.includes(1) }"
        >
          <use xlink:href="#b" />
          <rect
            width="39"
            height="47"
            x=".5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="27">1</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(2)"
          @keydown="e => handleKey(e, 2)"
          aria-label="Reifen 2"
          :aria-checked="readonly ? null : checkedItems.includes(2).toString()"
          :class="{ checked: readonly && checkedItems.includes(2) }"
        >
          <use xlink:href="#c" />
          <rect
            width="39"
            height="47"
            x="108.5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="123.5" y="27">2</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(3)"
          @keydown="e => handleKey(e, 3)"
          aria-label="Reifen 3"
          :aria-checked="readonly ? null : checkedItems.includes(3).toString()"
          :class="{ checked: readonly && checkedItems.includes(3) }"
        >
          <use xlink:href="#d" />
          <rect
            width="39"
            height="47"
            x=".5"
            y="64.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="91">3</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(4)"
          @keydown="e => handleKey(e, 4)"
          aria-label="Reifen 4"
          :aria-checked="readonly ? null : checkedItems.includes(4).toString()"
          :class="{ checked: readonly && checkedItems.includes(4) }"
        >
          <use xlink:href="#e" />
          <rect
            width="39"
            height="47"
            x="108.5"
            y="64.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="123.5" y="91">4</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(5)"
          @keydown="e => handleKey(e, 5)"
          aria-label="Reifen 5"
          :aria-checked="readonly ? null : checkedItems.includes(5).toString()"
          :class="{ checked: readonly && checkedItems.includes(5) }"
        >
          <use xlink:href="#f" />
          <rect
            width="39"
            height="47"
            x=".5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="155">5</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(200 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(6)"
          @keydown="e => handleKey(e, 6)"
          aria-label="Reifen 6"
          :aria-checked="readonly ? null : checkedItems.includes(6).toString()"
          :class="{ checked: readonly && checkedItems.includes(6) }"
        >
          <use xlink:href="#g" />
          <rect
            width="39"
            height="47"
            x="108.5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="123.5" y="155">6</tspan>
          </text>
        </g>
        <path
          fill="#0077C0"
          fill-rule="nonzero"
          d="M240 88h68v1h-68zm34.5 1v63h-1V89zm0 64v63h-1v-63zm-34.5-1h68v1h-68zm0 64h68v1h-68z"
          opacity=".2"
        />
        <path d="M264 84V64h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 80V68m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <path d="M264 240v-20h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 236v-12m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <text
          fill="#191919"
          font-family="OpenSans-Regular, Open Sans"
          font-size="16"
        >
          <tspan x="36.5" y="281">
            Erntemaschine, Güllefahrzeug, Anhänger, Gezogene Feldspritze
          </tspan>
        </text>
        <text
          fill="#1d1d1b"
          font-family="OpenSans-Regular, Open Sans"
          font-size="12"
        >
          <tspan x="274" y="313" text-anchor="middle">
            Bitte betroffene Reifen und Position wählen
          </tspan>
        </text>
      </g>
    </svg>
    <!-- 8 tyres -->
    <svg
      v-if="numOfTyres === 8"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="548"
      height="344"
      viewBox="0 0 548 344"
    >
      <defs>
        <rect id="b" width="43" height="51" x="46.5" y="-1.5" rx="5" />
        <rect id="c" width="43" height="51" x="-1.5" y="-1.5" rx="5" />
        <rect id="d" width="43" height="51" x="154.5" y="-1.5" rx="5" />
        <rect id="e" width="43" height="51" x="202.5" y="-1.5" rx="5" />
        <rect id="f" width="43" height="51" x="46.5" y="126.5" rx="5" />
        <rect id="g" width="43" height="51" x="-1.5" y="126.5" rx="5" />
        <rect id="h" width="43" height="51" x="154.5" y="126.5" rx="5" />
        <rect id="i" width="43" height="51" x="202.5" y="126.5" rx="5" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#0077C0"
          fill-rule="nonzero"
          d="M192 88h164v1H192z"
          opacity=".2"
        />
        <path
          fill="#0077C0"
          fill-rule="nonzero"
          d="M192 216h164v1H192z"
          opacity=".2"
        />
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(1)"
          @keydown="e => handleKey(e, 1)"
          aria-label="Reifen 1"
          :aria-checked="readonly ? null : checkedItems.includes(1).toString()"
          :class="{ checked: readonly && checkedItems.includes(1) }"
        >
          <use xlink:href="#c" />
          <rect
            width="39"
            height="47"
            x=".5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="27">1</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(2)"
          @keydown="e => handleKey(e, 2)"
          aria-label="Reifen 2"
          :aria-checked="readonly ? null : checkedItems.includes(2).toString()"
          :class="{ checked: readonly && checkedItems.includes(2) }"
        >
          <use xlink:href="#b" />
          <rect
            width="39"
            height="47"
            x="48.5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="63.5" y="27">2</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(3)"
          @keydown="e => handleKey(e, 3)"
          aria-label="Reifen 3"
          :aria-checked="readonly ? null : checkedItems.includes(3).toString()"
          :class="{ checked: readonly && checkedItems.includes(3) }"
        >
          <use xlink:href="#d" />
          <rect
            width="39"
            height="47"
            x="156.5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="171.5" y="27">3</tspan>
          </text>
        </g>

        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(4)"
          @keydown="e => handleKey(e, 4)"
          aria-label="Reifen 4"
          :aria-checked="readonly ? null : checkedItems.includes(4).toString()"
          :class="{ checked: readonly && checkedItems.includes(4) }"
        >
          <use xlink:href="#e" />
          <rect
            width="39"
            height="47"
            x="204.5"
            y=".5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="219.5" y="27">4</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(5)"
          @keydown="e => handleKey(e, 5)"
          aria-label="Reifen 5"
          :aria-checked="readonly ? null : checkedItems.includes(5).toString()"
          :class="{ checked: readonly && checkedItems.includes(5) }"
        >
          <use xlink:href="#g" />
          <rect
            width="39"
            height="47"
            x=".5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="15.5" y="155">5</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(6)"
          @keydown="e => handleKey(e, 6)"
          aria-label="Reifen 6"
          :aria-checked="readonly ? null : checkedItems.includes(6).toString()"
          :class="{ checked: readonly && checkedItems.includes(6) }"
        >
          <use xlink:href="#f" />
          <rect
            width="39"
            height="47"
            x="48.5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="63.5" y="155">6</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(7)"
          @keydown="e => handleKey(e, 7)"
          aria-label="Reifen 7"
          :aria-checked="readonly ? null : checkedItems.includes(7).toString()"
          :class="{ checked: readonly && checkedItems.includes(7) }"
        >
          <use xlink:href="#h" />
          <rect
            width="39"
            height="47"
            x="156.5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="171.5" y="155">7</tspan>
          </text>
        </g>
        <g
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(8)"
          @keydown="e => handleKey(e, 8)"
          aria-label="Reifen 8"
          :aria-checked="readonly ? null : checkedItems.includes(8).toString()"
          :class="{ checked: readonly && checkedItems.includes(8) }"
        >
          <use xlink:href="#i" />
          <rect
            width="39"
            height="47"
            x="204.5"
            y="128.5"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan x="219.5" y="155">8</tspan>
          </text>
        </g>
        <path
          fill="#0077C0"
          fill-rule="nonzero"
          d="M274.5 89v63h-1V89zm0 64v63h-1v-63z"
          opacity=".2"
        />
        <path d="M264 84V64h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 80V68m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <path d="M264 240v-20h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 236v-12m2.8 2.9-2.8-2.9-2.9 2.9"
        />

        <text
          fill="#191919"
          font-family="OpenSans-Regular, Open Sans"
          font-size="16"
        >
          <tspan x="163.4" y="281">Traktor mit Zwillingsbereifung</tspan>
        </text>
        <text
          fill="#1d1d1b"
          font-family="OpenSans-Regular, Open Sans"
          font-size="12"
        >
          <tspan x="274" y="313" text-anchor="middle">
            Bitte betroffene Reifen und Position wählen
          </tspan>
        </text>
      </g>
    </svg>
    <!-- 12 tyres -->
    <svg
      v-if="numOfTyres === 12"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="548"
      height="344"
      viewBox="0 0 548 344"
    >
      <defs>
        <rect id="box-1" width="43" height="51" x="-1.5" y="-1.5" rx="5" />
        <rect id="box-2" width="43" height="51" x="46.5" y="-1.5" rx="5" />
        <rect id="box-3" width="43" height="51" x="154.5" y="-1.5" rx="5" />
        <rect id="box-4" width="43" height="51" x="202.5" y="-1.5" rx="5" />
        <rect id="box-5" width="43" height="51" x="-1.5" y="62.5" rx="5" />
        <rect id="box-6" width="43" height="51" x="46.5" y="62.5" rx="5" />
        <rect id="box-7" width="43" height="51" x="154.5" y="62.5" rx="5" />
        <rect id="box-8" width="43" height="51" x="202.5" y="62.5" rx="5" />
        <rect id="box-9" width="43" height="51" x="-1.5" y="126.5" rx="5" />
        <rect id="box-10" width="43" height="51" x="46.5" y="126.5" rx="5" />
        <rect id="box-11" width="43" height="51" x="154.5" y="126.5" rx="5" />
        <rect id="box-12" width="43" height="51" x="202.5" y="126.5" rx="5" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#0077C0"
          fill-rule="nonzero"
          d="M192 88h164v1h-164zm82.5 1v63h-1V89zm0 64v63h-1v-63zm-82.5-1h164v1h-164zm0 64h164v1h-164z"
          opacity=".2"
        />
        <g
          v-for="t in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
          :key="t"
          fill="#FFF"
          transform="translate(152 64)"
          :role="readonly ? null : 'checkbox'"
          :tabindex="readonly ? null : 0"
          @click="toggleInput(t)"
          @keydown="e => handleKey(e, t)"
          :aria-label="`Reifen ${t}`"
          :aria-checked="readonly ? null : checkedItems.includes(t).toString()"
          :class="{ checked: readonly && checkedItems.includes(t) }"
        >
          <use :xlink:href="`#box-${t}`" />
          <rect
            width="39"
            height="47"
            :x="
              0.5 +
              ((t - 1) % 4) * 48 +
              ([3, 4, 7, 8, 11, 12].includes(t) ? 60 : 0)
            "
            :y="0.5 + Math.floor((t - 1) / 4) * 64"
            fill="none"
            stroke="#1D1D1B"
            stroke-linejoin="square"
            stroke-opacity=".1"
            rx="4"
          />
          <text
            fill="#1D1D1B"
            fill-opacity=".4"
            fill-rule="nonzero"
            font-family="OpenSans, Open Sans"
            font-size="16"
            letter-spacing="-.1"
          >
            <tspan
              :x="
                19.5 +
                ((t - 1) % 4) * 48 +
                ([3, 4, 7, 8, 11, 12].includes(t) ? 60 : 0)
              "
              :y="27 + Math.floor((t - 1) / 4) * 64"
              text-anchor="middle"
            >
              {{ t }}
            </tspan>
          </text>
        </g>
        <path d="M264 84V64h20v20z" />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 80V68m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <path d="M264 240v-20h20v20z" />

        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 80V68m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <path
          stroke="#0077C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M273.9 236v-12m2.8 2.9-2.8-2.9-2.9 2.9"
        />
        <text
          fill="#191919"
          font-family="OpenSans-Regular, Open Sans"
          font-size="16"
        >
          <tspan x="239.6" y="281">Sonstiges</tspan>
        </text>
        <text
          fill="#1d1d1b"
          font-family="OpenSans-Regular, Open Sans"
          font-size="12"
        >
          <tspan x="274" y="313" text-anchor="middle">
            Bitte betroffene Reifen und Position wählen
          </tspan>
        </text>
      </g>
    </svg>
    <template v-if="!readonly">
      <input
        type="checkbox"
        :name="input.name + '[]'"
        :value="i"
        v-for="i in numOfTyres"
        :key="i"
        :checked="checkedItems.includes(i)"
        :disabled="readonly"
      />
    </template>

    <slot></slot>
  </fieldset>
</template>

<script>
export default {
  props: {
    input: {
      type: Object,
      required: true,
    },
    typeOfMachine: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.readonly) {
      return;
    }
    window.MyMessageBus.listen('message', msg => {
      this.numOfTyres = this.computeNumOfTyres(msg.typeOfMachine);
    });
  },
  data() {
    return {
      numOfTyres: this.computeNumOfTyres(this.typeOfMachine),
      checkedItems: this.input.value,
    };
  },
  watch: {
    numOfTyres() {
      // Reset checked items if number of tyres change.
      this.checkedItems = [];
    },
  },
  methods: {
    toggleInput(number) {
      if (this.readonly) {
        return;
      }
      this.checkedItems = this.checkedItems.includes(number)
        ? this.checkedItems.filter(i => i !== number)
        : [...this.checkedItems, number];
    },
    handleKey(e, number) {
      if (e.code === 'Space') {
        e.preventDefault();
        this.toggleInput(number);
      }
    },
    computeNumOfTyres(typeOfMachine) {
      const map = {
        Traktor: 4,
        Teleskoplader: 4,
        Radlader: 4,
        'Selbstfahrende Feldspritze': 4,
        Erntemaschine: 6,
        Güllefahrzeug: 6,
        Anhänger: 6,
        'Gezogene Feldspritze': 6,
        Sonstiges: 12,
        'Traktor mit Zwillingsbereifung': 8,
      };

      return map[typeOfMachine] || 0;
    },
  },
};
</script>

<style scoped>
svg {
  width: 100%;
  height: auto;
  background-color: #fafafa;
}
[role='checkbox'] {
  cursor: pointer;
  border-radius: 2px;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 1px solid black;
  }
}

input[type='checkbox'] {
  display: none;
}
[aria-checked] text {
  pointer-events: none;
}
.checked rect,
[aria-checked='true'] rect {
  stroke: #0077c0;
  stroke-opacity: 1;
  stroke-width: 1.5px;
}
.checked use,
[aria-checked='true'] use {
  stroke: #0077c0;
  stroke-opacity: 0.25;
  stroke-width: 3px;
}
.checked text,
[aria-checked='true'] text {
  fill-opacity: 1;
}
</style>
