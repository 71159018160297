.footer-home {
  @apply relative;

  &::before {
    @if $site == 'sailun' {
      @apply absolute block inset-x-0 top-0;

      z-index: -1;
      height: 36vw;
      background-image: url('/assets/images/sailun-bg.svg');
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
