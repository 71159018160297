table {
  @apply w-full;

  border-collapse: collapse;
}

tbody {
  & tr:nth-of-type(even) {
    & td {
      @apply bg-white;
    }
  }

  & tr:nth-of-type(odd) {
    & td {
      @apply bg-grey-lightest;
    }
  }
}
