.hero-big {
  @apply hidden;

  @nest .teaser-wrapper--home & {
    @screen md {
      @apply block font-bold text-4xl leading-16/12;
    }
  }
}

.hero-small {
  @apply font-bold text-shadow my-0 text-base leading-22/16;

  @screen md {
    @apply text-xl leading-26/20;
  }

  @screen lg {
    @apply text-3xl;
  }

  @nest .teaser-wrapper--home & {
    @apply text-white;

    @screen lg {
      @apply text-xl;
    }
  }
}
