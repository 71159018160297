.pill {
  @apply relative ml-2 inline-block bg-black px-1 text-sm font-bold leading-tight text-white;

  &::before {
    position: absolute;
    display: block;
    block-size: 0;
    border-block-end: 0.5rem solid transparent;
    border-block-start: 0.5rem solid transparent;
    border-inline-end: 0.5rem solid theme('colors.black');
    content: '';
    inline-size: 0;
    inset-block-start: 0;
    inset-inline-start: -0.5rem;
  }
}
