/*! purgecss start ignore */
.popper {
  @apply z-50 bg-white rounded p-5 border-none shadow-lg text-xs !important;
  @apply text-black text-left font-normal leading-16/11 !important;

  width: max-content !important;
  max-width: 240px;
  margin: 1.625rem !important;

  @screen md {
    max-width: 300px;
  }

  & .popper__arrow {
    @apply bg-transparent;

    border-width: 1rem !important;
    pointer-events: none;
  }

  &[x-placement^='top'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: theme('colors.white') transparent transparent transparent !important;
    inset-block-end: -2rem !important;
  }

  &[x-placement^='bottom'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: transparent transparent theme('colors.white') transparent !important;
    inset-block-start: -2rem !important;
  }

  &[x-placement^='left'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: transparent transparent transparent theme('colors.white') !important;
    inset-inline-end: -2rem !important;
  }

  &[x-placement^='right'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: transparent theme('colors.white') transparent transparent !important;
    inset-inline-start: -2rem !important;
  }
}

.popper-black {
  @apply text-white bg-black !important;

  &[x-placement^='top'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: theme('colors.black') transparent transparent transparent !important;
    inset-block-end: -2rem !important;
  }

  &[x-placement^='bottom'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: transparent transparent theme('colors.black') transparent !important;
    inset-block-start: -2rem !important;
  }

  &[x-placement^='left'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: transparent transparent transparent theme('colors.black') !important;
    inset-inline-end: -2rem !important;
  }

  &[x-placement^='right'] .popper__arrow {
    /* stylelint-disable-next-line */
    border-color: transparent theme('colors.black') transparent transparent !important;
    inset-inline-start: -2rem !important;
  }
}
/*! purgecss end ignore */
