.card {
  @apply relative shadow-md overflow-hidden w-full rounded;

  @nest .swiper-slide & {
    @apply shadow-none;
  }
}

.card__image {
  @apply block w-full;

  & picture,
  & img {
    @apply block w-full;
  }

  &::after {
    @apply absolute inset-0 block;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, #000 100%);
    content: '';

    @screen md {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        #000 100%
      );
    }
  }
}

.card__image--small {
  &::after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, #000 100%);
  }
}

.card__content {
  @apply p-5;
}
