@layer utilities {
  .border-fade-l::before {
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 20%,
      #e5e5e5 20%,
      #e5e5e5 80%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0)
    );
    content: '';
    inline-size: 1px;
    inset-block: 0;
    inset-inline-start: 0;
  }
}
