.hover-shadow {
  transition: 0.2s box-shadow ease-out, 0.2s border ease-out !important;

  &:hover {
    @apply shadow-lg border-transparent;
  }
}

.hover {
  &:hover {
    & .hover-shadow {
      @apply shadow-lg border-transparent;
    }
  }
}
