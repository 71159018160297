<template>
  <gmap-map
    ref="map"
    :center="center"
    :options="{
      clickableIcons: false,
      styles: mapsStyles,
      mapTypeControl: false,
      streetViewControl: false,
    }"
    :zoom="zoom"
    map-type-id="roadmap"
    style="width: 100%; height: 100%"
    @idle="idle"
    @zoom_changed="zoomHandler"
  >
    <gmap-marker
      v-if="pinnedAddress"
      :position="pinnedAddress.geometry.location"
      :icon="icon"
      @click="clickPinnedLocation"
    />

    <gmap-cluster :max-zoom="13" :styles="clusterStyles" :zoom-on-click="true">
      <marker-component
        v-for="(dealer, index) in dealers"
        v-if="dealer.location"
        :key="index"
        :dealer="dealer"
      />
    </gmap-cluster>

    <gmap-info-window
      v-if="infoWindowPosition"
      :position="infoWindowPosition"
      :options="infoWindowOptions"
    >
      <div v-on-clickaway="closeInfoWindow" class="text-center">
        <div class="relative">
          <h1
            class="text-xs font-bold py-10px px-10 md:pl-5 border-b border-grey-light"
          >
            {{ activeDealer ? activeDealer.title : locationTitle }}
          </h1>

          <button
            class="absolute right-0 top-0 w-30px h-9 mr-1 flex items-center"
            @click="closeInfoWindow"
          >
            <svg class="text-primary fill-current w-6 h-6">
              <use xlink:href="#icon-close" />
            </svg>
          </button>
        </div>

        <div
          :class="{
            'justify-between': $mq !== 'lg' && activeDealer,
            'justify-center': $mq === 'lg' || !activeDealer,
          }"
          class="py-10px px-5 flex"
        >
          <a
            :href="computeRouteLink()"
            target="_blank"
            rel="noopener"
            class="text-xxs text-grey no-underline font-bold flex justify-center items-center whitespace-no-wrap dealer-entry-routing"
          >
            {{ activeDealer ? 'Route planen' : 'Route zum nächsten Händler' }}
            <svg class="w-3 h-3 ml-2 text-primary fill-current">
              <use xlink:href="#icon-navigate" />
            </svg>
          </a>
          <button
            v-if="activeDealer && $mq !== 'lg'"
            class="button button-primary button-sm md:button-default dealer-entry-details"
            @click="clickDetailsButton(activeDealer)"
          >
            <span>Details</span>
          </button>
        </div>
      </div>
    </gmap-info-window>
  </gmap-map>
</template>

<script>
import scrollIntoView from 'scroll-into-view-if-needed';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import { mapState } from 'vuex';
import mapsStyles from '../config/mapsStyles';
import icons from '../icons';
import { loadGoogle } from '../tools';
import MarkerComponent from './MarkerComponent.vue';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCxKbdh4GdLjIEQeWPfYl87tOOezFxP058',
    v: '3.36',
  },
});

const color = SITE === 'lassa' ? '#e10e21' : '#005fa3';

let mapIsLoaded = false;

export default {
  components: {
    MarkerComponent,
    GmapCluster,
  },

  data() {
    return {
      infoWindowOptions: {
        pixelOffset: { width: 0, height: 118 },
      },
      icon: null,
      clusterStyles: null,
      mapsStyles: mapsStyles[SITE],
      newPinnedAddress: false,
      zoom: 7,
      center: { lat: 48.2050164, lng: 13.3457582 },
    };
  },

  computed: {
    ...mapState({
      activeDealer: state => state.dealers.activeDealer,
      hoveredDealer: state => state.dealers.hoveredDealer,
      pinnedAddress: state => state.dealers.pinnedAddress,
      visibleMarkers: state => state.dealers.visibleMarkers,
      dealers: state => state.dealers.dealers,
      geoLocation: state => state.dealers.geoLocation,
      infoWindowPosition: state => state.dealers.infoWindowPosition,
    }),

    nearestDealer() {
      return this.$store.getters.nearestDealer;
    },

    locationTitle() {
      return this.geoLocation ? 'Ihr Standort' : 'Ihr angegebener Ort';
    },
  },

  watch: {
    pinnedAddress(value) {
      if (value) {
        this.zoom = 14;
        this.newPinnedAddress = true;
        const { bounds } = value;
        this.$refs.map.$mapObject.fitBounds(bounds);
        this.$refs.map.$mapObject.panToBounds(bounds);
      }
    },

    activeDealer(value) {
      if (value) {
        this.zoom = 14;
        this.$refs.map.$mapObject.panTo(value.location);
      }
    },
  },

  mounted() {
    this.clusterStyles = [
      {
        width: 40,
        height: 40,
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
          icons[SITE].mapCluster,
        )}`,
        textColor: color,
      },
      {
        width: 40,
        height: 40,
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
          icons[SITE].mapCluster,
        )}`,
        textColor: color,
      },
      {
        width: 40,
        height: 40,
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
          icons[SITE].mapCluster,
        )}`,
        textColor: color,
      },
    ];

    loadGoogle().then(() => {
      this.fitBounds();
      this.icon = {
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
          icons[SITE].mapPinFilled,
        )}`,
        scaledSize: new google.maps.Size(29, 40),
      };
    });
  },

  methods: {
    fitBounds() {
      const bounds = new google.maps.LatLngBounds();
      this.dealers.forEach(dealer => {
        if (dealer.location) {
          bounds.extend({
            lat: dealer.location.lat,
            lng: dealer.location.lng,
          });
        }
      });
    },

    zoomHandler(value) {
      if (value < this.zoom) {
        this.closeInfoWindow();
      }
      this.zoom = value;
    },

    idle() {
      const bounds = this.$refs.map.$mapObject.getBounds();

      // Don't do stuff on page load.
      if (!mapIsLoaded) {
        mapIsLoaded = true;
      } else if (!this.activeDealer) {
        this.$store.dispatch('setListIsAutoScrolling', true);

        this.$nextTick(() => {
          scrollIntoView(document.querySelector('[data-dealer]'), {
            behavior: 'smooth',
            block: 'start',
          });
        });

        setTimeout(() => {
          this.$store.dispatch('setListIsAutoScrolling', false);
        }, 1000);
      }

      if (bounds) {
        this.$store.dispatch('setBounds', bounds);

        if (this.newPinnedAddress) {
          if (this.dealers.length && this.visibleMarkers.length < 4) {
            this.$refs.map.$mapObject.setZoom(
              this.$refs.map.$mapObject.getZoom() - 1,
            );
          } else {
            this.newPinnedAddress = false;
          }
        } else if (this.dealers.length && this.visibleMarkers.length === 0) {
          this.$refs.map.$mapObject.setZoom(
            this.$refs.map.$mapObject.getZoom() - 1,
          );
        }
      }
    },

    closeInfoWindow() {
      this.$store.dispatch('closeInfoWindow');
    },

    computeRouteLink() {
      const googleMapsUrl = 'https://www.google.com/maps';

      const start = this.activeDealer ? this.activeDealer : this.nearestDealer;

      const params = [
        { daddr: `${start.street}, ${start.postalCode} ${start.city}` },
      ];

      if (this.pinnedAddress) {
        params[0].saddr = this.pinnedAddress.formatted_address;
      }

      return encodeURI(
        `${googleMapsUrl}?${params.map(param =>
          Object.entries(param)
            .map(([key, value]) => `${key}=${value}`)
            .join('&'),
        )}`,
      );
    },

    clickPinnedLocation() {
      this.$store.dispatch('setActiveDealer', null);
      this.$store.dispatch(
        'setInfoWindowPosition',
        this.pinnedAddress.geometry.location,
      );
    },

    clickDetailsButton(dealer) {
      this.$emit('showDealer', dealer);
    },
  },
};
</script>

<style lang="postcss">
/* stylelint-disable */
.cluster {
  & > img {
    clip: auto !important;
    filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.25));
  }
}

.gm-style {
  & > div {
    & > div {
      & > div {
        & > div {
          overflow: visible !important;

          & > img {
            filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.25));
          }
        }
      }
    }
  }
}
/* stylelint-enable */

.gm-style-iw {
  @apply rounded shadow-md;

  width: 260px;
  max-width: none !important;
  min-height: 67px;
  padding: 0 !important;
  background-color: #fff !important;

  /* stylelint-disable */
  & > div {
    width: 100%;
    max-width: 100% !important;
  }
  /* stylelint-enable */
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw-t {
  /* Hide arrow */
  &::after {
    display: none;
  }
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-pbc[style*='opacity: 1'] {
  z-index: 9999 !important;
}
</style>
