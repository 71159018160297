.notification {
  @apply rounded-md bg-primary-light overflow-hidden;
  @apply py-10px px-5 text-xxs leading-16/11;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notification-bar {
  animation: fadein 0.3s linear;

  @screen md {
    @apply items-center justify-center;
  }
}

.notification-link {
  @apply text-white no-underline font-bold;
  @apply text-xxs inline-flex items-center justify-end h-5;

  white-space: nowrap;

  &:hover,
  &:focus {
    @apply text-white;
  }

  @screen md {
    @apply ml-5 mr-8;
  }
}

.button-container {
  @apply hidden absolute flex-col justify-center;

  inset-block-start: 0.7rem;
  inset-inline-end: 1.25rem;

  @screen md {
    @apply flex;
  }

  & .notification-button {
    @apply hidden w-4 h-4;

    @screen md {
      @apply block;
    }
  }
}
