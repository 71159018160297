.more-link {
  @if $site == 'lassa' {
    @apply bg-inherit text-grey inline-flex flex-wrap;
    @apply text-xxs fill-current font-bold no-underline;
  }

  @if $site == 'goldGarantie' {
    @apply bg-inherit text-grey inline-flex flex-wrap;
    @apply text-xxs fill-current font-bold no-underline;
  }

  @if $site == 'sailun' {
    @apply inline-flex items-center rounded-full no-underline;
    @apply border text-xxs text-blue py-2px px-3 font-semibold;
    @apply animate-all !important;

    border-color: #bfd7e8;
    block-size: 20px;
  }
  @if $site == 'maxam' {
    @apply inline-flex items-center rounded-full no-underline;
    @apply border text-xxs text-blue py-2px px-3 font-semibold;
    @apply animate-all !important;

    border-color: #bfd7e8;
    block-size: 20px;
  }

  &:hover,
  &:focus {
    @if $site == 'sailun' {
      @apply border-blue;
    }
    @if $site == 'maxam' {
      @apply border-blue;
    }
  }

  & svg {
    @if $site == 'sailun' {
      display: none;
    }
  }
}

.more-link__icon {
  transition: transform 0.2s ease-out;
}
