<template>
  <li
    :class="{
      'border-transparent shadow-lg': focused,
      'border-grey-darker': !focused,
    }"
    class="hover hover-shadow rounded border my-5 cursor-pointer overflow-hidden bg-white"
  >
    <div
      class="flex flex-col md:flex-row"
      @mouseenter="hoverDealer"
      @mouseleave="leaveDealer"
      @click="clickDealer"
    >
      <template v-if="forceExpanded && !hasDetails">
        <button
          class="w-30px h-30px ml-auto mr-5 mt-5 md:hidden"
          @click.stop="toggleDetails"
        >
          <svg class="text-primary w-30px h-30px fill-current">
            <use xlink:href="#icon-close" />
          </svg>
        </button>
      </template>
      <div
        class="flex flex-shrink-0 justify-center align-middle md:w-40 md:flex-col"
      >
        <div
          class="mx-10px mt-10px md:mb-10px flex h-full items-center md:mr-0"
        >
          <img
            :src="logo"
            :alt="dealer.title"
            loading="lazy"
            class="mx-auto block max-h-full md:w-full"
          />
        </div>
      </div>
      <template v-if="forceExpanded && !hasDetails">
        <button
          class="w-30px h-30px ml-auto mr-5 hidden md:block"
          @click.stop="toggleDetails"
        >
          <svg class="text-primary w-30px h-30px fill-current">
            <use xlink:href="#icon-close" />
          </svg>
        </button>
      </template>
      <div class="flex-auto p-5">
        <div class="flex items-start">
          <h1 class="leading-seminormal flex-auto text-sm font-bold">
            {{ dealer.title }}
          </h1>
          <div
            v-if="dealer.exactDistance"
            class="rounded text-xxs leading-16/11 py-2px ml-2 px-2 font-bold shadow-outline"
          >
            {{ dealer.exactDistance.text }}
          </div>
          <div
            v-else-if="dealer.distance"
            class="rounded text-xxs leading-16/11 py-2px ml-2 px-2 font-bold shadow-outline"
          >
            {{ Math.floor(dealer.distance) }} km
          </div>
        </div>
        <address class="leading-16/12 text-xs not-italic">
          {{ fullAddress }}
        </address>
        <div
          class="flex flex-col justify-between gap-5 md:flex-row md:items-end md:gap-6"
        >
          <div class="mt-2 flex-auto text-xs md:mt-8 md:w-0">
            <div v-if="dealer.phoneNumber" class="mb-2px relative flex">
              <svg class="text-dynamic mr-2 h-3 w-3 fill-current">
                <use xlink:href="#icon-phone" />
              </svg>
              <a
                v-if="$mq === 'sm'"
                :href="`tel:${dealer.phoneNumber}`"
                class="link dealer-entry-phone"
                >{{ dealer.phoneNumber }}</a
              >
              <template v-else>
                <div v-if="hidePhoneNumber" class="relative flex-auto">
                  <span>{{ dealer.phoneNumber.slice(0, 6) }}…</span>

                  <div class="bg-fade absolute inset-0 flex w-full">
                    <button
                      class="more-link dealer-entry-reveal-phone ml-auto"
                      @click.stop="revealPhoneNumber"
                    >
                      <span class="leading-none">Nummer anzeigen</span>
                      <svg class="text-dynamic ml-2 h-3 w-3 fill-current">
                        <use xlink:href="#icon-eye" />
                      </svg>
                    </button>
                  </div>
                </div>
                <a
                  v-else
                  :href="`tel:${dealer.phoneNumber}`"
                  class="link dealer-entry-phone"
                  >{{ dealer.phoneNumber }}</a
                >
              </template>
            </div>
            <div v-if="dealer.emailAddress" class="mb-2px flex">
              <svg class="text-dynamic mr-2 h-3 w-3 fill-current">
                <use xlink:href="#icon-mail" />
              </svg>
              <a
                :href="`mailto:${dealer.emailAddress}`"
                class="link dealer-entry-email"
                >{{ dealer.emailAddress }}</a
              >
            </div>
            <div v-if="dealer.website" class="mb-2px flex">
              <svg class="text-dynamic mr-2 h-3 w-3 fill-current">
                <use xlink:href="#icon-web" />
              </svg>
              <a
                :href="formatUrl(dealer.website)"
                class="link"
                target="_blank"
                rel="noopener"
                >{{
                  dealer.website.replace(/^https?:\/\//, '').replace(/\/$/, '')
                }}</a
              >
            </div>
            <div v-if="currentOpeningHours" class="flex">
              <svg class="text-dynamic mr-2 h-3 w-3 flex-shrink-0 fill-current">
                <use xlink:href="#icon-hours" />
              </svg>
              <span v-if="formatDay(currentOpeningHours.col1) === getWeekday()"
                >Heute</span
              ><span v-else>{{ currentOpeningHours.col1 }}</span
              >: {{ formatTime(currentOpeningHours.col2) }}–{{
                formatTime(currentOpeningHours.col3)
              }}
              <template
                v-if="currentOpeningHours.col4 && currentOpeningHours.col5"
              >
                , {{ formatTime(currentOpeningHours.col4) }}–{{
                  formatTime(currentOpeningHours.col5)
                }}
              </template>
            </div>
          </div>
          <template v-if="hasDetails">
            <button
              v-if="!showDetails"
              key="showDetails"
              class="button button-primary button-sm dealer-entry-details"
              @click.stop="toggleDetails"
            >
              <span>Details</span
              ><span v-if="hasVideo" class="font-normal">&nbsp;mit Video</span>
            </button>
            <button
              v-else
              key="hideDetails"
              class="w-30px h-30px ml-auto"
              @click.stop="toggleDetails"
            >
              <svg class="text-primary w-30px h-30px fill-current">
                <use xlink:href="#icon-close" />
              </svg>
            </button>
          </template>
        </div>
      </div>
    </div>
    <div v-if="showDetails">
      <div v-if="hasSlider && currentOption === 'media'" class="relative">
        <div ref="swiper" class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-if="dealer.youtubeVideo">
              <div class="video">
                <iframe
                  width="550"
                  height="310"
                  :src="`https://www.youtube.com/embed/${dealer.youtubeVideo}?rel=0`"
                  title="YouTube Video"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              v-for="(image, id) in dealer.slides"
              :key="id"
              :class="{ gradient: dealer.slides.length > 1 }"
              class="swiper-slide"
            >
              <img
                :src="image.url"
                :alt="image.title"
                :width="image.width"
                :height="image.height"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div class="swiper-pagination-wrapper swiper-pagination-wrapper-inset">
          <div ref="swiperPagination" class="swiper-pagination" />
        </div>
      </div>
      <div v-if="hasTour && currentOption === '360'">
        <iframe
          :src="dealer.tour"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          title="360° Video Tour"
          width="100%"
          height="400"
        ></iframe>
      </div>
      <div
        v-if="hasSlider && hasTour"
        class="flex justify-center py-4"
        :class="{ 'border-b': hasText }"
      >
        <button
          type="button"
          class="radio-button"
          @click="currentOption = 'media'"
          :class="{
            'radio-button--selected': currentOption === 'media',
          }"
        >
          <svg
            class="pointer-events-none h-6 w-6 flex-shrink-0 fill-current"
            focusable="false"
            aria-hidden="true"
          >
            <title>Medien</title>
            <use v-bind="{ 'xlink:href': `#icon-media` }" />
          </svg>
          <span>
            <span class="md:hidden">Medien</span>
            <span class="hidden md:inline">
              {{
                hasSlider && hasVideo
                  ? 'Videos & Fotos'
                  : hasSlider
                  ? 'Fotos'
                  : hasVideo
                  ? 'Videos'
                  : ''
              }}
            </span>
          </span>
        </button>
        <popper
          :options="{ placement: 'bottom' }"
          :force-show="popperForceShow"
        >
          <div class="popper popper-black">
            <div class="leading-seminormal text-sm font-bold">
              Neu: 360° Rundgang!
            </div>
            <p class="leading-superloose mb-0 mt-5 text-xs">
              Schauen Sie sich bereits vorab Ihren gewünschten Händler in einem
              interaktivem Rundgang an.
            </p>
          </div>
          <button
            slot="reference"
            type="button"
            class="radio-button ml-4"
            @click="currentOption = '360'"
            :class="{
              'radio-button--selected': currentOption === '360',
            }"
          >
            <svg
              class="pointer-events-none h-6 w-6 flex-shrink-0 fill-current"
              focusable="false"
              aria-hidden="true"
            >
              <title>360° Rundgang</title>
              <use v-bind="{ 'xlink:href': `#icon-360` }" />
            </svg>
            <span>
              <span class="md:hidden">360°</span>
              <span class="hidden md:inline">360° Rundgang</span>
            </span>
          </button>
        </popper>
      </div>
      <div v-if="hasText" class="p-30px text-xs">
        <!-- <h2 class="text-sm mb-10px">Über uns</h2>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam sunt consequatur dolorum blanditiis error maiores dolorem qui enim deserunt pariatur?</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint aspernatur illum hic veritatis? Reprehenderit ad quos corporis numquam ullam! Facilis aliquam quas ea accusamus alias?</p> -->

        <template v-if="dealer.services.length">
          <h2 class="mb-3 text-sm">Unsere Services für Sie</h2>

          <ul class="md:columns-2 -my-2">
            <li
              v-for="service in dealer.services"
              :key="parseInt(service.id)"
              class="text-xxs li-bullet flex font-semibold"
            >
              {{ service.title }}
            </li>
          </ul>
        </template>

        <template v-if="dealer.openingHours && dealer.openingHours.length">
          <h2 class="mb-10px mt-10 text-sm">Öffnungszeiten</h2>

          <dl class="md:columns-2 -my-5px">
            <div
              v-for="(day, index) in dealer.openingHours"
              :key="index"
              class="flex leading-loose"
            >
              <dt class="text-dynamic text-xxs w-4">
                {{ formatDay(day.col1) }}
              </dt>
              <dd
                :class="{ 'font-bold': formatDay(day.col1) === getWeekday() }"
                class="text-xxs ml-1"
              >
                {{ formatTime(day.col2) }} – {{ formatTime(day.col3) }}
                <template v-if="day.col4 && day.col5">,</template>
                <template v-if="day.col4 && day.col5">
                  {{ formatTime(day.col4) }} – {{ formatTime(day.col5) }}
                </template>
              </dd>
            </div>
          </dl>
        </template>

        <div class="lg:-mx-10px lg:flex">
          <div v-if="dealer.documents.length" class="lg:px-10px flex-auto">
            <h2 class="mb-10px mt-10 text-sm">Unsere aktuellen Angebote</h2>

            <ul class="text-xxs">
              <li
                v-for="document in dealer.documents"
                :key="parseInt(document.id)"
              >
                <a
                  :href="document.url"
                  class="text-grey hover:text-primary flex font-bold no-underline"
                  target="_blank"
                  rel="noopener"
                >
                  {{ document.title }}
                  <svg class="text-dynamic ml-2 h-3 w-3 fill-current">
                    <use xlink:href="#icon-download" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div v-if="dealer.paymentOptions.length" class="lg:px-10px flex-auto">
            <h2 class="mb-10px mt-10 text-sm">Zahlungsarten</h2>

            <ul class="md:columns-2">
              <li
                v-for="paymentOption in dealer.paymentOptions"
                :key="parseInt(paymentOption.id)"
                class="text-xxs flex py-1"
              >
                <span class="flex items-center">
                  <svg class="text-dynamic mr-2 h-3 w-3 fill-current">
                    <use v-bind="{ 'xlink:href': icon(paymentOption) }" />
                  </svg>
                  <p class="my-0 mr-4 text-xs leading-loose">
                    {{ paymentOption.title }}
                  </p>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import getISODay from 'date-fns/get_iso_day';
import { Lazy, Pagination, Swiper } from 'swiper/dist/js/swiper.esm';
import Popper from 'vue-popperjs';
import { mapState } from 'vuex';

Swiper.use([Pagination, Lazy]);

export default {
  components: {
    Popper,
  },

  props: {
    dealer: {
      type: Object,
      required: true,
    },
    forceExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDetails: this.hasDetails && this.forceExpanded,
      dayArray: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      hidePhoneNumber: true,
      currentOption: '',
      popperForceShow: true,
    };
  },

  computed: {
    ...mapState({
      position: state => state.dealers.position,
      hoveredDealer: state => state.dealers.hoveredDealer,
      activeDealer: state => state.dealers.activeDealer,
    }),

    fullAddress() {
      return `${this.dealer.street}, ${this.dealer.postalCode} ${this.dealer.city}`;
    },

    focused() {
      return (
        (this.hoveredDealer && this.hoveredDealer.id === this.dealer.id) ||
        (this.activeDealer && this.activeDealer.id === this.dealer.id)
      );
    },

    currentOpeningHours() {
      if (this.dealer.openingHours) {
        const today = this.dealer.openingHours.find(
          day => this.formatDay(day.col1) === this.getWeekday(),
        );
        const todayIndex = getISODay(new Date());
        if (today) {
          return today;
        }

        for (let index = 0; index < this.dayArray.length; index += 1) {
          const weekday =
            this.dayArray[(todayIndex + index - 1) % this.dayArray.length];
          const nextDay = this.dealer.openingHours.find(
            day => this.formatDay(day.col1) === weekday,
          );

          if (nextDay) {
            return nextDay;
          }
        }
      }
      return null;
    },

    hasVideo() {
      return !!this.dealer.youtubeVideo;
    },

    hasTour() {
      return !!this.dealer.tour;
    },

    hasSlider() {
      return this.dealer.slides.length || this.hasVideo;
    },

    hasText() {
      return (
        this.dealer.services.length ||
        this.dealer.paymentOptions.length ||
        (this.dealer.openingHours !== null &&
          this.dealer.openingHours.length) ||
        this.dealer.documents.length
      );
    },

    hasDetails() {
      return this.hasText || this.hasTour || this.hasSlider;
    },

    logo() {
      return this.dealer.logo
        ? this.dealer.logo.url
        : `/assets/images/${SITE}-reifen-dealer-placeholder.svg`;
    },
  },

  watch: {
    currentOption(option) {
      if (option === '360') {
        this.popperForceShow = false;
      }
    },

    showDetails(isTrue) {
      if (isTrue) {
        this.currentOption = this.hasSlider ? 'media' : '360';
        this.$nextTick(() => {
          // eslint-disable-next-line
          const swiper = new Swiper(this.$refs.swiper, {
            spaceBetween: 20,
            slidesPerView: 1,
            preloadImages: false,
            lazy: {
              loadPrevNext: true,
              loadPrevNextAmount: 1,
            },
            watchOverflow: true,
            pagination: {
              el: this.$refs.swiperPagination,
              type: 'bullets',
              dynamicBullets: true,
              clickable: true,
            },
            loop: this.dealer.slides.length > 1 && !this.dealer.youtubeVideo,
          });
        });
      }
    },
  },

  methods: {
    weekday(dayIndex) {
      return this.dayArray[dayIndex];
    },

    getWeekday(day = getISODay(new Date())) {
      return this.weekday(day - 1);
    },

    toggleDetails() {
      if (this.forceExpanded) {
        this.$emit('close');
      }
      this.showDetails = !this.showDetails;
    },

    expandDetails() {
      this.showDetails = true;
    },

    collapseDetails() {
      this.showDetails = false;
    },

    hoverDealer() {
      if (this.$mq === 'lg') {
        this.$store.dispatch('setHoveredDealer', this.dealer);
      }
    },

    leaveDealer() {
      if (this.$mq === 'lg') {
        this.$store.dispatch('unsetHoveredDealer');
      }
    },

    clickDealer() {
      if (this.activeDealer && this.activeDealer.id === this.dealer.id) {
        this.$store.dispatch('closeInfoWindow');
      } else {
        const position = {
          lat: this.dealer.location.lat,
          lng: this.dealer.location.lng,
        };

        this.$store.dispatch('setInfoWindowPosition', position);
        this.$store.dispatch('setActiveDealer', this.dealer);

        if (typeof _paq !== 'undefined') {
          _paq.push(['trackEvent', 'UI Element', 'Click', 'Dealer Card']);
        }
      }
    },

    formatDay(dayString) {
      return dayString.slice(0, 2);
    },

    formatTime(timeString) {
      return timeString.split(':').slice(0, 2).join(':');
    },

    formatUrl(url) {
      if (url.startsWith('http')) {
        return url;
      }
      return `http://${url}`;
    },

    revealPhoneNumber() {
      this.hidePhoneNumber = false;
    },

    icon(option) {
      const title = option.title.toLowerCase();

      switch (title) {
        case 'kreditkarte':
        case 'bankomatzahlung':
        case 'visa':
        case 'mastercard':
          return '#icon-card';

        case 'barzahlung':
          return '#icon-cash';

        case 'finanzierung':
          return '#icon-hand-with-coins';

        default:
          return null;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
/*! purgecss start ignore */
.bg-fade {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #fff 40px,
    #fff
  );
}

.more-link {
  @if $site == 'sailun' {
    margin-block-start: -2px;
  }
}

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*! purgecss end ignore */
</style>
