.headroom {
  position: sticky;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  will-change: transform;
}

.headroom--pinned {
  @apply bg-white;

  transform: translateY(0);

  &.header {
    @apply shadow-md;
  }
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom--top {
  @apply bg-transparent;

  &.header {
    @apply shadow-none;
  }
}

.headroom--not-top {
  @apply bg-white;

  &.header {
    @apply shadow-md;
  }
}
