@layer components {
  .form-submit {
    & button {
      @apply button button-default;

      &[data-freeform-action='back'] {
        @apply button-secondary;
      }

      &[data-freeform-action='submit'] {
        @apply button-primary;

        margin-inline-start: auto;
      }
    }
  }

  .form-pages {
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    background-color: rgba(0, 119, 192, 0.06);
    font-size: 0.75rem;
    gap: 0.75rem;
    line-height: 1.25rem;
    list-style-type: none;
    padding-block: 1rem;
    padding-inline: 0.75rem;
    white-space: nowrap;

    & .form-page-step {
      color: rgba(25, 25, 25, 0.5);
      text-decoration: none;
    }

    & .form-page-active {
      & .form-page-step {
        color: #191919;
        font-weight: 600;
      }
    }
  }

  .form-page {
    display: flex;
    align-items: center;
  }
}
