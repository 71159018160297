@layer components {
  .input {
    @apply flex-1 appearance-none text-sm text-black;

    border-radius: 4px;
    background-color: #fafafa;
    block-size: 3rem;
    inline-size: 0;
    padding-inline: 0.5rem;
    transition: border 0.2s ease-out;

    &:focus {
      @apply border-primary outline-none;
    }

    &::placeholder {
      @apply font-normal;
    }
  }

  .form-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: 100%;

    &:not(:has(.input-dimensions)) {
      margin-block-end: 1rem;
    }

    @nest .linked & {
      margin-block-end: 0;
    }

    & :where(textarea) {
      padding-block: 1rem;
    }

    /* stylelint-disable prettier/prettier */
    & :where(
    input[type='text'],
    input[type='number'],
    input[type='tel'],
    input[type='email'],
    input[type='date'],
    textarea,
    select
  ) {
      @apply input;
    }
    /* stylelint-enable */

    /* stylelint-disable prettier/prettier */
    & :where(
    input[type='text'],
    input[type='number'],
    input[type='tel'],
    input[type='email'],
    input[type='date'],
    textarea
  ) {
      border: 1px solid #ededed;
    }
    /* stylelint-enable */

    & .has-suffix input {
      border-end-end-radius: 0;
      border-start-end-radius: 0;
      margin-inline-end: -1px;

      &:focus {
        z-index: 1;
      }
    }

    & > :where(label, legend) {
      @apply text-label flex text-xs font-bold;

      inline-size: 100%;
      line-height: 1.5;
      margin-block-end: 0.5rem;
    }

    & .file {
      flex-direction: column;
      gap: 1rem;
    }

    & .radio-group {
      display: flex;
      flex-direction: column;

      @screen md {
        flex-direction: row;

        & > * + * {
          margin-inline-start: 1.5rem;
        }
      }

      & label {
        line-height: 1.75em;
        padding-inline-start: calc(2ch + 0.5rem);

        @supports (selector(:has(*))) {
          position: relative;

          &:has(:focus-within:focus-visible) {
            border-radius: 4px;
            outline: 1px solid theme('colors.primary');
            outline-offset: 4px;
          }

          &::before,
          &::after {
            position: absolute;
            display: block;
            border-radius: 100%;
            content: '';
            inset-block-start: 0.375rem;
            inset-inline-start: 0;
          }

          &::before {
            border-width: 1px;
            border-color: #ededed;
            background-color: #fafafa;
            block-size: 1rem;
            color: theme('colors.primary');
            inline-size: 1rem;
          }

          &::after {
            block-size: 0.625rem;
            inline-size: 0.625rem;
            inset-block-start: 0.5625rem;
            inset-inline-start: 0.1875rem;
          }

          &:has(:checked) {
            &::before {
              background-color: #fafafa;
              color: #fff;
            }

            &::after {
              background-color: theme('colors.primary');
            }
          }
        }
      }

      & input {
        margin-inline-end: 0.5rem;
        margin-inline-start: calc(-2ch - 0.5rem);

        @supports (selector(:has(*))) {
          position: absolute;
          overflow: hidden;
          padding: 0;
          border-width: 0;
          block-size: 1rem;
          inline-size: 1rem;
          inset-inline-start: 0;
          margin-inline-end: 0;
          margin-inline-start: 0;
          opacity: 0;
          white-space: nowrap;
        }
      }
    }


    & .suffix {
      display: inline-flex;
      align-items: center;
      border: 1px solid #ededed;
      background-color: theme('colors.white');
      border-end-end-radius: 4px;
      border-start-end-radius: 4px;
      padding-inline: 0.75rem;
    }
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-block-start: 0.5rem;
    }

    & input {
      margin-inline-end: 0.5rem;

      @supports (selector(:has(*))) {
        position: absolute;
        overflow: hidden;
        padding: 0;
        border-width: 0;
        block-size: 1rem;
        inline-size: 1rem;
        inset-inline-start: 0;
        margin-inline-end: 0;
        margin-inline-start: 0;
        opacity: 0;
        white-space: nowrap;
      }
    }

    & label {
      line-height: 1.75em;
      padding-inline-start: calc(2ch + 0.5rem);

      @supports (selector(:has(*))) {
        position: relative;

        &:has(:focus-within:focus-visible) {
          border-radius: 4px;
          outline: 1px solid theme('colors.primary');
          outline-offset: 4px;
        }

        &::before,
        &::after {
          position: absolute;
          display: block;
          block-size: 1rem;
          content: '';
          inline-size: 1rem;
          inset-block-start: 0.375rem;
          inset-inline-start: 0;
        }

        &::before {
          border-width: 1px;
          border-color: #ededed;
          border-radius: 4px;
          background-color: #fafafa;
          color: theme('colors.primary');
        }

        &:has(:checked) {
          &::before {
            background-color: #fafafa;
            color: #fff;
          }

          &::after {
            @if $site == 'lassa' {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cpath d='M 3 8 l 3 3 l 7 -7' stroke='%23e10e21' fill='none' stroke-width='3' /%3E%3C/svg%3E%0A");
            }
            @if $site == 'sailun' {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cpath d='M 3 8 l 3 3 l 7 -7' stroke='%23005fa3' fill='none' stroke-width='3' /%3E%3C/svg%3E%0A");
            }
            @if $site == 'goldGarantie' {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cpath d='M 3 8 l 3 3 l 7 -7' stroke='%23ed1c24' fill='none' stroke-width='3' /%3E%3C/svg%3E%0A");
            }
            @if $site == 'maxam' {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cpath d='M 3 8 l 3 3 l 7 -7' stroke='%230077c0' fill='none' stroke-width='3' /%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }
  }

  .input-dimensions {
    & input,
    & .dimension-abbr {
      margin-block-end: 1rem;
    }

    & input {
            &:nth-of-type(1) {
        inline-size: calc(3ch + 1rem + 2px);
      }
      &:nth-of-type(2) {
        inline-size: calc(2ch + 1rem + 2px);
      }
      &:nth-of-type(3) {
        inline-size: calc(2ch + 1rem + 2px);
      }
      &:nth-of-type(4) {
        inline-size: calc(3ch + 1rem + 2px);
      }
      &:nth-of-type(5) {
        inline-size: calc(1ch + 1rem + 2px);
      }
    }
  }
}
