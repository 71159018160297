.table {
  @apply rounded border-separate shadow-md;

  border-spacing: 0;

  & th {
    @apply px-5px py-10px text-xxs text-left;

    & > div {
      @apply h-30px;
    }

    &:first-child {
      @apply pl-4;

      @if $site == 'lassa' {
        @apply rounded-tl;
      }

      @if $site == 'sailun' {
        @apply rounded-tl;
      }
    }

    &:last-child {
      @apply pr-4;

      @if $site == 'lassa' {
        @apply rounded-tr;
      }

      @if $site == 'sailun' {
        @apply rounded-tr;
      }
    }
  }

  & td {
    @apply px-5px py-4 text-xs;

    & > div {
      @apply h-30px;
    }
  }

  & tbody {
    & tr {
      & td {
        &:first-child {
          @apply pl-4;
        }

        &:last-child {
          @apply pr-4;
        }
      }

      &:last-child {
        & td {
          &:first-child {
            @if $site == 'lassa' {
              @apply rounded-bl;
            }

            @if $site == 'sailun' {
              @apply rounded-bl;
            }
          }

          &:last-child {
            @if $site == 'lassa' {
              @apply rounded-br;
            }

            @if $site == 'sailun' {
              @apply rounded-br;
            }
          }
        }
      }
    }
  }
}

.table--mobile {
  & tbody {
    & tr {
      & td {
        @apply pl-5 pr-2;
      }

      &:nth-child(7n):not(:last-child) {
        @apply border-b border-white;
      }

      &:nth-child(7n) {
        & th,
        & td {
          @apply pb-4;
        }
      }

      &:nth-child(7n),
      &:nth-child(7n + 1),
      &:nth-child(7n + 2),
      &:nth-child(7n + 3),
      &:nth-child(7n + 4),
      &:nth-child(7n + 5),
      &:nth-child(7n + 6) {
        & td {
          @apply bg-grey-lightest;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(14n + 1),
      &:nth-child(14n + 2),
      &:nth-child(14n + 3),
      &:nth-child(14n + 4),
      &:nth-child(14n + 5),
      &:nth-child(14n + 6),
      &:nth-child(14n + 7) {
        & td {
          @apply bg-white;
        }
      }
    }
  }

  vertical-align: baseline;

  & th {
    &:first-child {
      @apply rounded-none pl-5 pr-2;
    }

    @apply bg-primary leading-16/11 text-white;
  }

  & tr:first-child {
    & th {
      @apply rounded-tl;
    }
    & td:last-child {
      @apply rounded-tr;
    }
  }

  & tr:last-child {
    & th {
      @apply rounded-bl;
    }
    & td:last-child {
      @apply rounded-br;
    }
  }
}

.thead {
  @apply leading-16/11 text-white;

  & th {
    @apply bg-primary;
  }
}

.td-alt {
  @apply px-10px pt-45px !important;
}

table.border-separate {
  --gap: 16px;
  border-spacing: var(--gap) 0;
  inline-size: calc(100% + 2 * var(--gap));
  margin-inline: calc(var(--gap) * -1);

  & td,
  th {
    padding-block: 0.75rem;
    padding-inline: 0.875rem;

    @screen md {
      padding-block: 1rem;
      padding-inline: 1.5rem;
    }
  }

  @screen md {
    --gap: 24px;
  }
}
