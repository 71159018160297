.drop-shadow {
  filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.25));
}

.drop-shadow-lg {
  filter: drop-shadow(0px 24px 16px rgba(0, 0, 0, 0.5));
}

.drop-shadow-real {
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    width: 100%;
    height: 30px;
    background: radial-gradient(#191919 0%, rgba(25, 25, 25, 0) 70%);
    content: '';
    inset-block-end: -15px;
    inset-inline-start: 0;
    opacity: 0.5;
  }
}
