.swiper-pagination {
  white-space: nowrap;

  /* Make unsere Modelle pagination be on the border */
  &.swiper-pagination--on-border {
    position: relative;
    display: inline;
    background: inherit;
    inset-block-end: -6px;
  }
}

.swiper-pagination-bullet-active {
  @if $site == 'lassa' {
    @apply bg-primary;
  }

  @if $site == 'sailun' {
    @apply bg-blue;
  }
}

.swiper-pagination-bullet {
  @apply mx-2px;

  border: none;
  border-radius: 2px 3px;
  transition: transform 0.2s, left 0.2s;

  @if $site == 'lassa' {
    width: 24px;
    height: 10px;
  }

  @if $site == 'sailun' {
    @apply w-10 h-1 rounded-full;
  }
}

.swiper-pagination-wrapper-inset {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  inset-block-end: 70px;

  & .swiper-pagination-bullet {
    @apply bg-white opacity-100;
  }

  & .swiper-pagination-bullet-active {
    @if $site == 'lassa' {
      @apply bg-primary;
    }

    @if $site == 'sailun' {
      @apply bg-blue;
    }
  }
}

.swiper-pagination-bullets-dynamic {
  & .swiper-pagination-bullet {
    @if $site == 'lassa' {
      transform: scale(0) skewX(-20deg) translateX(-10px);
    }

    @if $site == 'sailun' {
      transform: scale(0);
    }
  }

  & .swiper-pagination-bullet-active-main {
    @if $site == 'lassa' {
      transform: scale(1) skewX(-20deg) translateX(-6px);
    }

    @if $site == 'sailun' {
      transform: scale(1);
    }
  }

  & .swiper-pagination-bullet-active-prev,
  & .swiper-pagination-bullet-active-next {
    @if $site == 'lassa' {
      transform: scale(0.66) skewX(-20deg) translateX(-10px);
    }

    @if $site == 'sailun' {
      transform: scale(0.66);
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  @apply button-pagination;

  background-image: none !important;

  @if $site == 'lassa' {
    width: 70px;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.swiper-button-prev {
  inset-inline-start: -50px;
}

.swiper-button-next {
  inset-inline-end: -50px;
}

.swiper-slide.h-64 {
  @apply h-64;
}

.swiper-slide {
  @apply h-auto;

  & > picture {
    & > img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.swiper-testimonials {
  @screen md {
    @apply grow;

    padding-block-end: 60px;
  }

  & ~ .swiper-button-prev,
  & ~ .swiper-button-next {
    &:focus {
      outline: 1px solid -webkit-focus-ring-color;
    }

    &::after {
      @apply deskew;

      position: absolute;
      display: block;
      content: '';
      inset-block: -40px;
    }
  }

  & ~ .swiper-button-prev::after {
    background: linear-gradient(90deg, #fff0 30%, #fff 40%);
    inset-inline: 0 -40px;
  }

  & ~ .swiper-button-next::after {
    background: linear-gradient(-90deg, #fff0 30%, #fff 40%);
    inset-inline: -40px 0;
  }
}
