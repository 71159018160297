.cta {
  @apply hidden;

  @screen md {
    @nest .home & {
      @apply flex;
    }
  }

  @screen lg {
    @apply flex;
  }
}
