.skip-link {
  position: absolute;
  z-index: 100;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 0 0 0.5rem 0;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  color: currentColor !important;
  font-size: 0.875rem;
  font-weight: 800;
  inset-block-start: 0;
  inset-inline-start: 0;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateY(-200%);
  transition: transform 0.2s ease !important;

  &:focus {
    transform: translateY(0);
  }
}
