html:not(.user-is-tabbing) a:focus,
html:not(.user-is-tabbing) button:focus,
html:not(.user-is-tabbing) input:focus,
html:not(.user-is-tabbing) select:focus,
html:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

html.user-is-tabbing a:focus,
html.user-is-tabbing button:focus,
html.user-is-tabbing input:focus,
html.user-is-tabbing select:focus,
html.user-is-tabbing textarea:focus {
  outline: 3px solid #7fb3f9;

  & + .focus-style {
    border-radius: inherit;
    outline: 3px solid #7fb3f9;
  }

  & + .state::before {
    outline: 3px solid #7fb3f9;
  }
}
