.testimonial {
  @apply text-black;

  @screen md {
    display: grid;
    grid-template-columns: 85px 85px 1fr;

    & + .testimonial {
      display: grid;
      grid-template-columns: 1fr 85px 85px;

      & .testimonial__images {
        grid-column: 2 / 4;
      }

      & .testimonial__content {
        grid-column: 1 / 3;
        grid-template-columns: 1fr 85px;
      }

      & .testimonial__quote {
        grid-column: 1 / 2;
      }
    }
  }

  @nest .swiper-slide & {
    @apply mt-25;

    @screen md {
      @apply mt-0;
    }
  }
}

.testimonial__images {
  @apply -scooch-t-half text-center;

  font-size: 0;

  @screen md {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    transform: none;
  }
}

@screen md {
  .testimonial__content {
    display: grid;
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    grid-template-columns: 85px 1fr;
  }

  .testimonial__quote {
    grid-column: 2 / 3;
  }
}
