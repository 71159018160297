.m-align {
  & > * {
    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }
}
