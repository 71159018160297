<template>
  <div ref="form" class="rounded">
    <div
      :class="{
        'md:h-full': dealerIndex,
        'shadow-lg': focus,
        'my-5': headroom,
      }"
      class="rounded shadow-md p-5 bg-white z-10 flex flex-col justify-between dealer-search"
    >
      <div
        v-if="showHeading && dealerIndex"
        class="flex justify-between items-center mb-5"
      >
        <h2 class="text-primary text-xl font-bold leading-semitight">
          Händlersuche
        </h2>

        <div class="hidden md:flex mt-3 md:mt-0">
          <slot :dealerIndex="dealerIndex" />
        </div>
      </div>
      <form
        :action="dealerIndex ? dealerIndex : ''"
        :class="{
          'dealer-search-input-content': dealerIndex,
          'dealer-search-input-index': !dealerIndex,
        }"
        method="GET"
        v-on="inputListeners"
      >
        <div class="flex justify-between items-center">
          <label for="dealer" class="label mb-0">{{ labelText }}</label>
          <button
            v-if="!dealerIndex && checkRequirements"
            :disabled="fetchingLocation"
            type="button"
            class="more-link"
            @click="getGeoLocation"
          >
            <div class="flex items-center whitespace-no-wrap">
              <span class="leading-none">{{
                this.$mq === 'sm' ? 'Standort' : 'Standort verwenden'
              }}</span>
              <svg class="w-3 h-3 ml-2 text-primary fill-current">
                <use xlink:href="#icon-gps" />
              </svg>
            </div>
          </button>
        </div>
        <div class="border-b-2 border-primary flex justify-end items-center">
          <input
            type="search"
            id="dealer"
            class="h-12 flex-1 font-bold outline-none bg-transparent"
            placeholder="Ort, Straße oder PLZ …"
            name="place"
            v-if="!focused"
            @focus="handleFocus"
          />
          <gmap-autocomplete
            v-else
            id="dealer"
            ref="search"
            :value="value"
            :component-restrictions="{ country: ['at'] }"
            :options="options"
            type="search"
            class="h-12 flex-1 font-bold outline-none bg-transparent"
            placeholder="Ort, Straße oder PLZ …"
            @focus.native="focus = true"
            @blur.native="focus = false"
            @place_changed="placeChanged"
          />
          <button
            v-if="value"
            class="h-12 w-12 flex items-center justify-center"
            aria-label="Löschen"
            @click="clear"
          >
            <svg class="text-primary fill-current w-4 h-4">
              <use xlink:href="#icon-close" />
            </svg>
          </button>
          <button
            class="relative h-12 w-12 border-fade-l text-primary fill-current flex items-center justify-center"
            aria-label="Suchen"
            type="submit"
            @focus="focus = true"
            @blur="focus = false"
            @click="clickButton"
          >
            <svg class="w-4 h-4">
              <use xlink:href="#icon-dealer-search-input" />
            </svg>
          </button>
        </div>
      </form>

      <div v-if="dealerIndex" class="text-right mt-3 md:hidden">
        <slot :dealerIndex="dealerIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import Headroom from 'headroom.js';
import NProgress from 'nprogress';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { loadGoogle } from '../tools';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCxKbdh4GdLjIEQeWPfYl87tOOezFxP058',
    v: '3.36',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

export default {
  props: {
    dealerIndex: {
      type: String,
      default: null,
    },
    dealers: {
      type: Array,
      default: () => [],
    },
    headroom: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      value: this.place,
      focus: false,
      fetchingLocation: false,
      focused: false,
      options: {
        types: ['geocode'],
      },
    };
  },

  computed: {
    labelText() {
      if (this.label) {
        return this.label;
      }
      if (this.dealerIndex) {
        return 'Händler suchen in';
      }
      return this.$mq !== 'sm'
        ? `Finden Sie einen von ${this.dealers.length} ${
            SITE === 'lassa' ? 'Lassa' : 'Sailun'
          }-Händlern ganz in Ihrer Nähe`
        : `${SITE === 'lassa' ? 'Lassa' : 'Sailun'}-Händler finden:`;
    },

    inputListeners() {
      if (this.dealerIndex) {
        return null;
      }
      return {
        submit(event) {
          event.preventDefault();
        },
      };
    },

    pinnedAddress() {
      return this.$store.state.dealers.pinnedAddress;
    },

    listIsAutoScrolling() {
      return this.$store.state.dealers.listIsAutoScrolling;
    },
  },

  watch: {
    listIsAutoScrolling() {
      this.headroomElement.unpin();
    },
  },

  mounted() {
    if (this.headroom) {
      this.initHeadroom();

      this.setWidth();
      window.addEventListener('resize', this.setWidth);
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.setWidth);
      });
    }
  },

  created() {
    const query = new URLSearchParams(document.location.search);

    this.$store.dispatch('setDealers', this.dealers);

    if (query.has('place')) {
      loadGoogle().then(() => {
        this.geocode({ address: query.get('place') });
      });
    }
  },

  methods: {
    handleFocus() {
      this.focused = true;

      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.$el.focus();
        }
      });
    },

    initHeadroom() {
      const vm = this;
      this.headroomElement = new Headroom(this.$refs.form, {
        offset: 180,
        tolerance: {
          up: 0,
          down: 20,
        },
        classes: {
          // when element is initialised
          initial: 'headroom',
          // when scrolling up
          pinned: 'headroom--pinned',
          // when scrolling down
          unpinned: 'headroom--unpinned',
        },
        onPin() {
          if (vm.listIsAutoScrolling) {
            this.elem.classList.remove(this.classes.pinned);
            this.elem.classList.add(this.classes.unpinned);
          } else {
            this.elem.classList.add(this.classes.pinned);
            this.elem.classList.remove(this.classes.unpinned);
          }
        },
      });

      this.headroomElement.init();
    },

    setWidth() {
      this.$refs.form.style.width = `${this.$refs.form.parentElement.clientWidth}px`;
    },

    placeChanged(place) {
      if (place && place.name) {
        this.setValue(place);
      }
    },

    clickButton() {
      if (!this.dealerIndex) {
        this.geocode({ address: this.$refs.search.$refs.input.value });
      }
    },

    geocode(location, restrict = true) {
      return new Promise(resolve => {
        const request = {
          ...location,
        };

        if (restrict) {
          request.componentRestrictions = {
            country: 'AT',
          };
        }

        this.clear();

        NProgress.start();

        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode(request, (results, status) => {
          NProgress.done();
          if (status === 'OK') {
            const bestResult = results[0];
            this.setValue(bestResult);

            if (bestResult.partial_match) {
              this.$store.dispatch(
                'setWarning',
                `Kein eindeutiges Ergebnis für <span class="font-bold capitalize">
                ${request.address}</span> gefunden.`,
              );
            }
            resolve();
          }

          if (status === 'ZERO_RESULTS') {
            this.$store.dispatch(
              'setWarning',
              `Keine Ergebnisse für <span class="font-bold capitalize">
              ${request.address}</span> gefunden.`,
            );
          }
        });
      });
    },

    setValue(value) {
      if (value && value.geometry) {
        this.value = value.formatted_address;

        if (!this.dealerIndex) {
          window.history.replaceState(
            null,
            document.title,
            `${document.location.origin}${document.location.pathname}?place=${this.value}`,
          );
        }

        this.$store.dispatch('setPinnedAddress', value);
      }
    },

    clear() {
      window.history.replaceState(
        null,
        document.title,
        `${document.location.origin}${document.location.pathname}`,
      );

      this.$store.dispatch('setGeoLocation', false);
      this.$store.dispatch('setPinnedAddress', null);
      this.$store.dispatch('setWarning', null);
      this.fetchingLocation = false;
      this.value = null;
    },

    checkRequirements() {
      return typeof navigator.geolocation !== 'undefined';
    },

    getGeoLocation() {
      this.clear();
      this.fetchingLocation = true;

      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        loadGoogle().then(() => {
          this.geocode({ location: pos }, false).then(() => {
            this.fetchingLocation = false;
          });
        });
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
/*! purgecss start ignore */
.headroom {
  position: absolute !important;
  /* stylelint-disable-next-line */
  transition: transform 0.6s ease-in-out, box-shadow 0.6s ease-in-out !important;
}

.headroom--not-top {
  position: fixed !important;
  background-color: transparent;
  inset-block-start: 0;
}
/*! purgecss end ignore */
</style>
