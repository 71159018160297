<template>
  <div class="text-black">
    <form
      v-if="!success"
      ref="form"
      :class="{ pending: pending }"
      action=""
      method="POST"
      @submit.prevent="submit"
    >
      <slot />
    </form>
    <div v-if="error" class="notification my-5">
      <div class="mb-4 font-bold">Hoppala…</div>
      <p>Irgendetwas ist schief gelaufen.</p>
    </div>
    <div v-else-if="success" ref="success" class="notification my-5">
      <slot name="success" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NProgress from 'nprogress';

export default {
  data() {
    return {
      pending: false,
      success: false,
      error: false,
      errorMessages: [],
    };
  },

  methods: {
    submit() {
      if (this.pending) {
        return;
      }
      const formData = new FormData(this.$refs.form);

      this.pending = true;
      NProgress.start();

      axios
        .post('/', formData)
        .then(response => {
          this.pending = false;
          if (response.data.success) {
            this.success = true;
            NProgress.done();
          }
          if (response.data.errors) {
            this.errorMessages = Object.values(response.data.errors).map(
              error => error[0],
            );
            this.error = true;
          }
        })
        .catch(error => {
          console.error(error);
          this.error = true;
          NProgress.done();
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
.pending {
  opacity: 0.5;
}
</style>
