.skew {
  @if $site == 'lassa' {
    transform: skewX(-20deg);
  }
}

.deskew {
  display: inline-block;

  @if $site == 'lassa' {
    transform: skewX(20deg);
  }
}
