@layer components {
  .rte {
    & > p {
      margin: 0;
    }

    & > :not(h2) + :not(h2) {
      margin-block-start: 0.5rem;
    }

    & > ul {
      padding-inline-start: 2ch;

      & > li {
        position: relative;

        &::before {
          position: absolute;
          background-color: theme('colors.primary');
          block-size: 1px;
          content: '';
          inline-size: 8px;
          inset-block-start: 0.75em;
          inset-inline-start: -2ch;
        }
      }
    }

    & li + li {
      margin-block-start: 0.5rem;
    }

    &.lg\:grid-cols-12 {
      @screen lg {
        & h2 {
          grid-column: 1 / 6;
        }

        & :not(h2) {
          grid-column: 7 / 13;
        }

        & > :not(h2) + :not(h2) {
          margin-block-start: -5rem;
        }
      }
    }
  }
}
