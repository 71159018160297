.label {
  @apply text-label mb-2 inline-block w-full text-xs font-bold;

  display: flex;
  line-height: 1.333333;
  white-space: nowrap;
}

.tyre-label {
  block-size: 25px;
  inline-size: 25px;

  &:last-child {
    inline-size: 30px;
  }

  @screen md {
    block-size: 30px;
    inline-size: 30px;
  }
}
