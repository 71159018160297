<template>
  <div :key="key">
    <div class="label">
      <span>{{ heading }}</span>
      <span class="ml-auto font-normal text-black" v-if="instructions">{{
        instructions
      }}</span>
    </div>
    <div>
      <template v-if="fileList && fileList.length">
        <div
          class="whitespace-no-wrap bg-gray reset-button flex h-10 w-0 items-center overflow-hidden rounded-full px-6"
          v-for="(file, idx) in Array.from(fileList)"
          :key="idx"
        >
          <span class="flex-1">{{
            `${file.name} (${(file.size / 1024 / 1024).toFixed(1)}MB)`
          }}</span>
          <button class="ml-2 border-0" type="button" @click="remove(idx)">
            <svg class="text-primary fill-current" width="14px" height="14px">
              <use xlink:href="#icon-close" />
            </svg>
            <span class="sr-only">Datei entfernen</span>
          </button>
        </div>
      </template>
      <div class="relative">
        <label
          :for="`attachment-${name}`"
          class="button h-10"
          :class="buttonStyle"
          v-show="!fileList || fileList.length === 0"
        >
          <span>{{ label }}</span>
        </label>
        <input
          :id="`attachment-${name}`"
          :name="name"
          ref="input"
          type="file"
          accept=".pdf, .jpg, .jpeg, .png, .gif, .tiff"
          class="absolute top-0 h-10 w-full cursor-pointer opacity-0"
          :required="required"
          :multiple="multiple"
          @change="fileChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import reportValidity from 'report-validity';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'attachment',
    },
    instructions: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: String,
      default: 'button-primary',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFileSizeInKB: {
      type: Number,
      default: 8192,
    },
  },

  data() {
    return {
      fileList: undefined,
      key: 0,
    };
  },

  mounted() {
    if (this.value) {
      const file = this.dataURLtoFile(this.value.data, this.value.name);
      const container = new DataTransfer();
      container.items.add(file);
      this.$refs.input.files = container.files;
      this.$refs.input.dispatchEvent(new Event('change'));
    }
  },

  methods: {
    fileChange({ target }) {
      target.setCustomValidity('');

      if (target.files && target.files.length) {
        this.fileList = target.files;
        /**
         *  @var maxFileSize Maximum file size in bytes.
         * */
        const maxFileSizeInBytes = this.maxFileSizeInKB * 1024;

        Array.from(target.files).forEach(file => {
          if (file.size > maxFileSizeInBytes) {
            target.setCustomValidity(
              `Die Datei darf die Größe von ${
                maxFileSizeInBytes / 1024 / 1024
              }MB nicht überschreiten`,
            );
          }
          reportValidity(target);
        });
      } else {
        this.reset();
      }
    },

    reset() {
      this.fileList = undefined;
      this.$refs.input.value = '';
    },

    remove(idx) {
      this.fileList = Array.from(this.fileList).filter(
        (_item, index) => index !== idx,
      );
      const dt = new DataTransfer();
      this.key += 1;

      this.$nextTick(() => {
        Array.from(this.fileList).forEach(file => {
          dt.items.add(file);
        });
        this.$refs.input.files = dt.files;
        this.$refs.input.dispatchEvent(new Event('change'));
      });
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style lang="postcss" scoped>
.reset-button {
  background-color: #fbfbfb;
  font-size: 0.875rem;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button {
  margin-bottom: 0;
}
</style>
