.offset-image {
  @apply relative;

  @media (max-width: 1247px) {
    & img {
      @apply w-full;
    }
  }

  @screen lg {
    block-size: 380px;
    float: inline-end;
    inline-size: 265px;
    margin-block-start: 230px;
    shape-outside: content-box;

    .swiper-container {
      @apply h-full py-5 pl-5;

      inline-size: 550px;
      max-inline-size: none;
    }

    .swiper-pagination-wrapper {
      inline-size: 550px;
    }

    img {
      max-inline-size: none;
    }
  }
}
