.nav {
  justify-content: center;

  @if $site == 'maxam' {
    justify-content: end;
    margin-inline-end: 20px;
  }
  @if $site == 'goldGarantie' {
    justify-content: end;
    margin-inline-end: 20px;
  }
}

.navigation-bar__toggle {
  block-size: 30px;
  inline-size: 30px;
}

.navigation__item {
  margin-inline: 0.625rem;
  padding-block: 0.625rem;

  @if $site == 'maxam' {
    margin-inline: 1.25rem;
  }

  &:nth-child(n + 6) {
    display: none;
  }

  &.active {
    @if $site == 'lassa' {
      @apply border-primary;
    }

    @if $site == 'sailun' {
      @apply border-blue;
    }

    @if $site == 'goldGarantie' {
      @apply border-primary;
    }

    @if $site == 'xxlGarantie' {
      @apply border-primary;
    }

    @if $site == 'maxam' {
      & a {
        @apply text-primary;
      }
    }
  }
}
