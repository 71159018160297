/**
 * main.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */

@import 'tailwindcss/base';

/**
 * This injects any component classes registered by plugins.
 *
 */

@import 'tailwindcss/components';

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

@import './elements/_html';
@import './elements/_body';
@import './elements/_input';
@import './elements/_abbr';
@import './elements/_p';
@import './elements/_a';
@import './elements/_h';
@import './elements/_table';
@import './elements/_button';
@import './elements/_fieldset';
@import './generic/_focus';
@import './generic/_scroll-anchor';
@import './components/_button';
@import './components/_swiper';
@import './components/_card';
@import './components/_testimonial';
@import './components/_header';
@import './components/_headroom';
@import './components/_navigation';
@import './components/_select';
@import './components/_link';
@import './components/_menu';
@import './components/_maps';
@import './components/_pac';
@import './components/_nprogress';
@import './components/_label';
@import './components/_video';
@import './components/_services';
@import './components/_404';
@import './components/_page-title';
@import './components/_content-section';
@import './components/_table';
@import './components/_popper';
@import './components/_pill';
@import './components/_input';
@import './components/_offset-image';
@import './components/_service-teaser';
@import './components/_image-rounded';
@import './components/_more-link';
@import './components/_tyre-group-teaser';
@import './components/_tyre-group-hero';
@import './components/_footer';
@import './components/_heading';
@import './components/_notification';
@import './components/_modal';
@import './components/_hero';
@import './components/_cta';
@import './components/_image-cover';
@import './components/_logo';
@import './components/_teaser';
@import './components/_li';
@import './components/_dimension-abbr';
@import './components/_matomo';
@import './components/_radio-button';
@import './components/_breadcrumbs';
@import './components/_skip-link';
@import './components/_form';
@import './components/_rte';

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */

@import 'tailwindcss/utilities';

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

@import 'utilities/_flexboxgrid';
@import 'utilities/_order';
@import 'utilities/_bg';
@import 'utilities/_skew';
@import 'utilities/_text';
@import 'utilities/_text-shadow';
@import 'utilities/_line-clamp';
@import 'utilities/_scooch';
@import 'utilities/_border-fade';
@import 'utilities/_columns';
@import 'utilities/_shadow';
@import 'utilities/_drop-shadow';
@import 'utilities/_vue';
@import 'utilities/_hover';
@import 'utilities/_m';
@import 'utilities/_animate';
@import 'utilities/_gradient';
@import 'utilities/_hide-scrollbar';
@import 'utilities/_grow';
@import 'utilities/_fullbleed';
@import 'utilities/_sr-only';
@import 'utilities/_isolate';

.freeform-file-drag-and-drop {
  inline-size: 100%;

  & [data-placeholder] {
    font-size: 1rem !important;
  }
}
