.service-section {
  @apply border rounded border-grey-light relative mb-20 p-5;

  @screen lg {
    @apply max-w-740px mx-auto;
  }
}

.service-section-title {
  @apply text-dynamic leading-seminormal pr-2 text-sm;

  position: absolute;
  background: #fff;
  inset-block-start: -0.7rem;
  inset-inline-start: 0;

  @screen md {
    @apply leading-semitight text-xl;
  }
}
