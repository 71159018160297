.gradient {
  &::after {
    @apply block absolute inset-0;

    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 66%,
      rgba(0, 0, 0, 1)
    );
    content: '';
  }
}

.gradient-fade {
  &::after {
    @apply block fixed left-0 top-0;

    z-index: -1;
    overflow: hidden;
    width: 66.666%;
    aspect-ratio: 1;
    background: linear-gradient(
      0.85deg,
      rgba(0, 119, 192, 0.4) 0%,
      rgba(0, 119, 192, 0) 100%
    );
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    content: '';
  }
}
