.tyre-group-teaser {
  @apply h-full flex flex-col hover text-black no-underline rounded;

  @if $site == 'sailun' {
    margin: 0 1px;
    background-color: white;
    background-image: url('/assets/images/tyre-group-teaser-bg.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    @apply text-black !important;
  }
}

.tyre-group-teaser__info {
  @apply flex flex-col rounded-b relative z-10 flex-1;

  min-height: 250px;

  @screen md {
    @apply mt-0 rounded;
  }

  @if $site == 'lassa' {
    @apply bg-white;
  }
}
