p {
  @apply my-10px;

  &:first-child {
    @apply mt-0;
  }

  &:last-child {
    @apply mb-0;
  }

  &:empty {
    margin-block-start: 0;
  }
}
