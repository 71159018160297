input {
  @apply text-sm;

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  accent-color: theme('colors.primary');
}

::-ms-clear {
  display: none;
}

:is(input, textarea)::placeholder {
  color: black;
}
