.content-section {
  @apply my-20 bg-white;

  &.content-section--dense {
    @apply my-10;
  }

  &:first-child {
    @apply mt-0;
  }

  &:last-child {
    @apply mb-0;
  }

  & ol {
    @apply mt-5;

    counter-reset: section;

    & li {
      @apply border border-primary rounded py-10px pr-10px mb-10px relative pl-6;

      counter-increment: section;

      &:last-child {
        @apply mb-0;
      }

      &::before {
        @apply bg-primary absolute left-0 flex items-center justify-center rounded-full text-xs font-bold text-white;
        block-size: 20px;
        content: counter(section);
        inline-size: 20px;

        inset-block-start: 50%;
        /* stylelint-disable-next-line property-no-unknown */
        margin-block-start: -10px;
        margin-inline-start: -10px;
      }
    }
  }

  @if $site != 'maxam' {
    & ul {
      /* stylelint-disable-next-line no-descending-specificity */
      & li {
        @apply relative mb-2 pl-5;

        text-indent: -5px;

        &:last-child {
          @apply mb-0;
        }

        &::before {
          @apply relative inline-flex items-center justify-center;
          @apply border border-primary rounded-full text-xs font-bold text-white;
          block-size: 10px;
          content: '';
          inline-size: 10px;
          inset-block-start: -5px;
          inset-inline-start: -0.5rem;
          margin-inline-start: -5px;
        }
      }
    }
  }

  @if $site == 'maxam' {
    & ul {
      padding-inline-start: 2ch;

      & > li {
        position: relative;

        &::before {
          position: absolute;
          background-color: theme('colors.primary');
          block-size: 1px;
          content: '';
          inline-size: 8px;
          inset-block-start: 0.75em;
          inset-inline-start: -2ch;
        }
      }
    }

    & li + li {
      margin-block-start: 0.5rem;
    }
  }
}
