.li-bullet {
  @apply relative ml-3 pl-3 leading-insane;

  &::before {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    content: '';
    inset-block-start: 50%;
    inset-inline-start: 0;
    transform: translateY(-50%);

    @if $site == 'lassa' {
      border: 1px solid theme('colors.primary');
    }

    @if $site == 'sailun' {
      border: 1px solid theme('colors.blue');
    }

    @if $site == 'goldGarantie' {
      border: 1px solid theme('colors.primary');
    }

    @if $site == 'xxlGarantie' {
      border: 1px solid theme('colors.primary');
    }
  }
}
