<template>
  <div>
    <div
      v-if="!visibleTyreGroups.length"
      class="flex flex-wrap mt-10 rounded-md bg-primary-light text-xxs px-5 border-t border-b border-primary-light"
    >
      <div class="w-full lg:w-auto my-2">
        <span class="font-bold"
          >Leider haben wir keine Reifen zu Ihren Kriterien gefunden.</span
        >
        <span v-if="dimensionFiltersAreSet"
          >Bitte ändern Sie die Angaben zur Reifengröße:</span
        >
      </div>

      <button
        v-if="dimensionFiltersAreSet"
        class="flex items-center ml-auto font-bold text-grey my-2 hover:text-primary animate-color"
        @click="resetFilters"
      >
        <span>Reifengröße zurücksetzen</span>
        <svg
          class="more-link__icon w-3 h-3 flex-shrink-0 ml-1 text-primary fill-current"
        >
          <use xlink:href="#icon-close" />
        </svg>
      </button>
    </div>
    <slot />
  </div>
</template>

<script>
let container;
let tyreGroupElements;

export default {
  props: {
    tyreGroups: {
      type: Array,
      required: true,
    },
  },

  computed: {
    visibleTyreGroups() {
      return this.$store.getters.visibleTyreGroups;
    },

    dimensionFiltersAreSet() {
      return (
        this.$store.state.tyres.width ||
        this.$store.state.tyres.height ||
        this.$store.state.tyres.diameter
      );
    },
  },

  mounted() {
    this.$store.dispatch('setTyreGroups', this.tyreGroups);
    container = this.$slots.default[0].elm;
    tyreGroupElements = Array.from(container.children);
  },

  methods: {
    resetFilters() {
      this.$store.dispatch('resetFilters');
    },
  },

  watch: {
    visibleTyreGroups(value) {
      tyreGroupElements.forEach(element => {
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });

      value.forEach(tyreGroup => {
        const element = tyreGroupElements.find(el => {
          return parseInt(el.dataset.id, 10) === tyreGroup.id;
        });
        container.appendChild(element);
      });
    },
  },
};
</script>

<style lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-active,
.fade-leave-to {
  transition: all 0.2s ease-in;
}
</style>
