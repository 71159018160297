<template>
  <modal
    :name="name"
    :adaptive="true"
    height="100%"
    width="100%"
    @before-open="beforeOpen"
  >
    <div class="flex justify-end p-5">
      <button @click="hide">
        <svg class="fill-current text-white" width="30px" height="30px">
          <use xlink:href="#icon-close" />
        </svg>
      </button>
    </div>
    <div class="flex flex-auto flex-col justify-center">
      <div class="mx-auto w-full max-w-xl">
        <div class="flex flex-col items-center justify-center">
          <div class="iframe-container">
            <iframe
              ref="iframe"
              :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
              class="iframe"
              width="100%"
              frameborder="0"
              title="YouTube Video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <button class="button button-sm button-primary mt-10" @click="hide">
            <span>Schließen</span>
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Vue from 'vue';
import VModal from 'vue-js-modal';

Vue.use(VModal);

export default {
  props: {
    name: {
      type: String,
      default: 'default',
    },
  },

  data() {
    return {
      videoId: null,
    };
  },

  methods: {
    beforeOpen({ params }) {
      this.videoId = params.videoId;
    },

    hide() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="postcss">
.v--modal {
  background-color: transparent !important;
  box-shadow: none !important;
}

.v--modal-overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.v--modal-box {
  @apply flex flex-col;

  width: 100% !important;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-block-start: 56.25%;
}

.iframe {
  @apply bg-black;

  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  left: 0;
  top: 0;
}
</style>
