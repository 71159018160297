<template>
  <div class="container my-20">
    <div class="row text-center md:-mx-30px">
      <div v-for="(item, key) in items" :key="key" class="col">
        <!-- This is where the ad gets inserted in the mounted lifecycle hook -->
        <div ref="ad" class="my-4" />

        <noscript>
          <a
            :href="`https://track.adform.net/C/?bn=${item.id};C=0`"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="
                `https://track.adform.net/adfserve/?bn=${item.id};srctype=4;ord=[timestamp]`
              "
              width="300"
              height="250"
              alt=""
            />
          </a>
        </noscript>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    // eslint-disable-next-line global-require
    this.postscribe = require('postscribe');
    this.$nextTick(() => {
      this.items.forEach((item, index) => {
        if (this.$refs.ad[index]) {
          this.postscribe(
            this.$refs.ad[index],
            `<script src="https://track.adform.net/adfscript/?bn=${item.id}" language="javascript"><\/script>`,
            {
              error: err => {
                console.error(err.msg);
              },
            },
          );
        }
      });
    });
  },
};
</script>

<style lang="postcss">
.ads {
  @apply -mx-8 my-5;

  @media (min-width: 1085px) {
    @apply m-0;
  }
}
</style>
