.pac-container {
  @apply shadow-md border-none rounded-t-none rounded-b p-5 pr-3 mt-2px;

  box-sizing: content-box;

  &.pac-logo::after {
    content: none;
  }
}

.pac-item {
  @apply border-none mr-3;

  line-height: 20px;
}

.pac-item-selected {
  @apply bg-grey-lightest;
}

.pac-icon {
  display: none;
}
