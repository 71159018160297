@layer utilities {
  @variants responsive {
    .fullbleed {
      position: relative;
      inline-size: 100vw;
      inset-inline: 50%;
      margin-inline: -50vw;
    }

    .no-fullbleed {
      position: initial;
      inline-size: auto;
      inset-inline: unset;
      margin-inline: unset;
    }
  }
}
